import { ImageListItem, styled } from "@mui/material";
import * as React from "react";

export const DropzoneImageListElement = styled(ImageListItem)({
  /*   width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative", */
  "&:hover": {
    "& .MuiImageListItemBar-positionBottom": {
      display: "flex !important",
    },
  },
});
