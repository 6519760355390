import { styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export const DatePickerCustom = styled(DatePicker, {
  shouldForwardProp(propName) {
    return propName !== "fullWidth";
  },
})<any>(({ fullWidth }) => ({
  width: fullWidth ? "100%" : "auto",
  "& .MuiInputBase-root": {
    borderRadius: "10px",
  },
}));
