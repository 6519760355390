import { Box, styled } from "@mui/material";

export const ModalEditClinicBox = styled(Box)({
  width: "700px",
  padding: "16px 25px !important",
});

export const FilterDiv = styled("div")({
  marginTop: "5px",
  padding: "10px",
  border: "2px solid #b9bcbf",
  justifyContent: "space-between",
  marginBottom: "1%",
});

export const ContainerTop = styled("div")({
  marginTop: "10px",
  display: "flex",
  justifyContent: "space-between",
});

export const FilterStyle = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "1%",
  height: "40px",
});

export const LineDivBox = styled("div")({
  color: "black !important",
  display: "flex",
});
