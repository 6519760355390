import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Paper, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  ContainerTop,
  FilterStyle,
  LineDivBox,
  ModalEditClinicBox,
} from "./style";
import Button from "../../components/buttom";
import "./style.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DefaultTemplate from "../../templates/default";
import { StaticTable } from "../../components/static-table";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { DefaultButton } from "../../components/default-button";
import { toast } from "react-toastify";
import {
  createClinicApi,
  getClinics,
  getClinicsCategories,
  updateClinicApi,
  deleteClinicApi,
} from "../../services/modules/clinic";
import Categorias from "../categorias";
import { setLoadingExternally } from "../../providers/loading.provider";

interface ModalClinicInfo {
  id?: string;
  team: boolean;
  name: string;
  email?: string;
  phone: string;
  address: {
    cep?: string;
    street: string;
    number?: string;
    complement?: string;
    neighborhood: string;
    city: string;
    state: string;
  };
  categories: {
    id?: string;
    name: string;
    value: string;
    fromClinic?: boolean;
  }[];
}
const Clinicas = ({ isAdmin }: { isAdmin: boolean }) => {
  const [clinicName, setClinicName] = useState("");
  const [modalInfos, setModalInfos] = useState<ModalClinicInfo>({
    id: "",
    name: "",
    team: false,
    phone: "",
    address: {
      city: "",
      neighborhood: "",
      street: "",
      state: "",
    },
    categories: [],
    email: "",
  });
  const [deleteClinicProps, setDeleteClinicProps] = useState({
    id: "",
    name: "",
  });
  const [openModalDeleteClinic, setOpenModalDeleteClinic] = useState(false);
  const [openModalCreateClinic, setOpenModalCreateClinic] = useState(false);
  const [isLoadingGetClinic, setIsLoadingGetClinic] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isLoadingGetCategory, setIsLoadingGetCategory] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [categorieIdEditModal, setCategorieIdEditModal] = useState<
    string | undefined
  >(undefined);
  const columnsCategories = [
    {
      id: "name",
      label: "Nome",
    },
    {
      id: "valueModal",
      label: "Valor",
    },
    {
      id: "owner",
      label: "Visualização",
    },
    {
      id: "actions",
      label: "Ações",
    },
  ];

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const columns = [
    {
      id: "edit",
      label: "",
      style: {
        width: "30px",
      },
    },
    {
      id: "name",
      label: "Nome",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "team",
      label: "Visualização",
    },
    {
      id: "delete",
      label: "Excluir",
    },
  ];
  const [debouncedTerm, setDebouncedTerm] = useState(clinicName);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(clinicName + "a");
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [clinicName]);

  useEffect(() => {
    if (debouncedTerm) {
      reloadSearch();
    }
  }, [debouncedTerm]);

  const closeModal = () => {
    setOpenModalCreateClinic(false);
    setModalInfos({
      id: "",
      name: "",
      team: false,
      phone: "",
      address: {
        city: "",
        complement: "",
        street: "",
        state: "",
        neighborhood: "",
      },
      categories: [],
      email: "",
    });
  };

  const openModalEditClinic = (clinic: ModalClinicInfo) => {
    getClinicsCategories(clinic.id!).then((data) => {
      const dataCategories = data.map((category: any) => {
        return {
          id: category.id,
          idTeam: category.idTeam,
          name: category.name,
          value: category.value,
          fromClinic: category.fromClinic,
        };
      });
      setCategories(formatCategories(dataCategories));
      setModalInfos({
        id: clinic.id,
        name: clinic.name,
        team: clinic.team,
        email: clinic.email,
        phone: clinic.phone,
        address: clinic.address,
        categories: dataCategories,
      });
      setOpenModalCreateClinic(true);
    });
  };

  const formatCategories = (categories: any[]): any => {
    return categories
      .map((category: any) => {
        return {
          ...category,
          owner: category.fromClinic
            ? "Clinica"
            : category.idTeam
            ? "Equipe"
            : "Pessoal",
          name: (
            <TextField
              style={{
                width: "220px",
              }}
              InputProps={{
                sx: {
                  height: "40px", // Altera a altura do campo de entrada
                },
              }}
              value={category.name}
              onChange={(e) => {
                setCategories(
                  formatCategories(
                    categories.map((c) =>
                      c.id === category.id
                        ? {
                            ...c,
                            name: e.target.value,
                            fromClinic: true,
                          }
                        : c
                    )
                  )
                );
              }}
              margin="normal"
            />
          ),
          valueModal: (
            <TextField
              type="number"
              style={{
                width: "120px",
              }}
              InputProps={{
                sx: {
                  height: "40px", // Altera a altura do campo de entrada
                },
              }}
              value={category.value}
              onChange={(e) => {
                setCategories(
                  formatCategories(
                    categories.map((c) =>
                      c.id === category.id
                        ? {
                            ...c,
                            value: e.target.value,
                            fromClinic: true,
                          }
                        : c
                    )
                  )
                );
              }}
              margin="normal"
            />
          ),
          actions: (
            <>
              {category.fromClinic ? (
                <DeleteOutline
                  style={{ cursor: "pointer", width: "30px", color: "red" }}
                  onClick={() => {
                    setCategories(
                      formatCategories(
                        categories.filter((c) => c.id !== category.id)
                      )
                    );
                  }}
                />
              ) : null}
            </>
          ),
        };
      })
      .sort((a, b) => (a.owner === "Clinica" ? -1 : 1));
  };

  const formatClinics = (clinics: any[]): any => {
    return clinics.map((clinic: any) => {
      return {
        ...clinic,
        team: clinic.idTeam ? (
          <span style={{ color: "blue" }}>Equipe</span>
        ) : (
          <span style={{ color: "green" }}>Pessoal</span>
        ),
        edit: (
          <Edit
            style={{ cursor: "pointer", width: "30px" }}
            onClick={() => openModalEditClinic(clinic)}
          />
        ),
        delete: (
          <DeleteOutline
            style={{ cursor: "pointer", width: "30px", color: "red" }}
            onClick={() => {
              setDeleteClinicProps({
                id: clinic.id,
                name: clinic.name,
              });
              setOpenModalDeleteClinic(true);
            }}
          />
        ),
      };
    });
  };
  const findClinics = () => {
    setIsLoadingGetClinic(true);
    getClinics(clinicName)
      .then((data: any[]) => {
        setClinics(formatClinics(data));
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingGetClinic(false);
      });
  };

  const reloadSearch = () => {
    findClinics();
  };

  const formatClinicInfo = (): ModalClinicInfo => {
    return {
      id: modalInfos.id,
      name: modalInfos.name,
      team: modalInfos.team,
      phone: modalInfos.phone,
      address: modalInfos.address,
      email: modalInfos.email,
      categories: categories
        .filter((category) => category.fromClinic)
        .map((category) => {
          return {
            id: category.id,
            name: category.name.props.value,
            value: category.valueModal.props.value,
          };
        }),
    };
  };

  const createClinic = () => {
    setLoadingExternally(true);
    createClinicApi(formatClinicInfo())
      .then(() => {
        closeModal();
        reloadSearch();
        toast.success("Clinica criada com sucesso!");
      })
      .catch(() => {
        setOpenModalCreateClinic(false);
      })
      .finally(() => setLoadingExternally(false));
  };

  const updateClinic = () => {
    setLoadingExternally(true);
    updateClinicApi(formatClinicInfo())
      .then(() => {
        closeModal();
        reloadSearch();
        toast.success("Clinica atualizada com sucesso!");
      })
      .catch(() => {
        setOpenModalCreateClinic(false);
      })
      .finally(() => setLoadingExternally(false));
  };

  const deleteClinic = () => {
    setLoadingExternally(true);
    deleteClinicApi(deleteClinicProps.id)
      .then(() => {
        setOpenModalDeleteClinic(false);
        toast.success("Clinica excluída com sucesso!");
        reloadSearch();
      })
      .finally(() => setLoadingExternally(false));
  };

  const formatToOriginalCategory = (categories: any[]) => {
    return categories.map((category) => {
      return {
        id: category.id,
        idTeam: category.idTeam,
        name: category.name.props.value,
        value: category.valueModal.props.value,
        fromClinic: category.fromClinic,
      };
    });
  };

  const generateId = () => {
    return Math.floor(Math.random() * 10000);
  };

  const addCategory = () => {
    setCategories(
      formatCategories([
        {
          id: generateId(),
          name: "",
          value: 0,
          fromClinic: true,
        },
        ...formatToOriginalCategory(categories),
      ])
    );
  };

  return (
    <DefaultTemplate title="Clinicas" setContainer={false}>
      {/* Filter */}
      <ContainerTop>
        <FilterStyle>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: "0",
              borderRadius: "10px",
              width: 400,
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.125)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquise por uma clinica..."
              value={clinicName}
              onChange={(e) => setClinicName(e.target.value)}
            />
            <IconButton
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={reloadSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </FilterStyle>
        <Button
          style={{ cursor: "pointer", width: "180px", height: "40px" }}
          text={"Cadastrar Clinica"}
          callback={() => setOpenModalCreateClinic(true)}
        />
      </ContainerTop>

      {/* Table */}
      <StaticTable
        rows={clinics}
        isLoading={isLoadingGetClinic}
        columns={columns}
      />

      {/* Modal */}
      <Modal open={openModalCreateClinic} onClose={() => closeModal()}>
        <ModalEditClinicBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "95vh", // Limita a altura máxima */
            overflowY: "auto", // Adiciona rolagem vertical
            bgcolor: "background.paper",
            boxShadow: "24",
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {modalInfos.id ? "Editar Clinica" : "Cadastro de Clinica"}
          </Typography>
          <LineDivBox>
            <TextField
              fullWidth
              label="Nome"
              value={modalInfos.name}
              onChange={(e) =>
                setModalInfos({
                  ...modalInfos,
                  name: e.target.value,
                })
              }
              margin="normal"
            />
          </LineDivBox>
          <LineDivBox>
            <TextField
              fullWidth
              label="Email"
              value={modalInfos.email}
              style={{ width: "150%" }}
              onChange={(e) =>
                setModalInfos({
                  ...modalInfos,
                  email: e.target.value,
                })
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Phone"
              value={modalInfos.phone}
              style={{ marginLeft: 20 }}
              onChange={(e) =>
                setModalInfos({
                  ...modalInfos,
                  phone: e.target.value,
                })
              }
              margin="normal"
            />
          </LineDivBox>
          {isAdmin ? (
            <>
              <Divider orientation="horizontal" />
              <Typography variant="subtitle1" gutterBottom>
                Tipo de Clínica
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={modalInfos.team}
                    onChange={(e) =>
                      setModalInfos({
                        ...modalInfos,
                        team: e.target.checked,
                      })
                    }
                  />
                }
                label="Equipe"
              />
              <Divider orientation="horizontal" />
            </>
          ) : null}
          <LineDivBox>
            <div>
              <Accordion
                expanded={expanded === "address"}
                onChange={handleChange("address")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="addressbh-content"
                  id="addressbh-header"
                  style={{
                    backgroundColor: "#e7e7e7",
                  }}
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Endereço
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <TextField
                    label="CEP"
                    style={{
                      backgroundColor: "#fff",
                      width: "130px",
                      marginRight: 10,
                    }}
                    value={modalInfos.address.cep}
                    onChange={(e) =>
                      setModalInfos({
                        ...modalInfos,
                        address: {
                          ...modalInfos.address,
                          cep: e.target.value,
                        },
                      })
                    }
                    margin="normal"
                  />
                  <TextField
                    label="Rua"
                    style={{
                      backgroundColor: "#fff",
                      width: "300px",
                      marginRight: 10,
                    }}
                    value={modalInfos.address.street}
                    onChange={(e) =>
                      setModalInfos({
                        ...modalInfos,
                        address: {
                          ...modalInfos.address,
                          street: e.target.value,
                        },
                      })
                    }
                    margin="normal"
                  />
                  <TextField
                    label="Numero"
                    style={{
                      backgroundColor: "#fff",
                      width: "100px",
                      marginRight: 10,
                    }}
                    value={modalInfos.address.number}
                    onChange={(e) =>
                      setModalInfos({
                        ...modalInfos,
                        address: {
                          ...modalInfos.address,
                          number: e.target.value,
                        },
                      })
                    }
                    margin="normal"
                  />
                  <LineDivBox>
                    <TextField
                      fullWidth
                      label="Bairro"
                      style={{
                        backgroundColor: "#fff",
                        width: "200px",
                        marginRight: 10,
                      }}
                      value={modalInfos.address.neighborhood}
                      onChange={(e) =>
                        setModalInfos({
                          ...modalInfos,
                          address: {
                            ...modalInfos.address,
                            neighborhood: e.target.value,
                          },
                        })
                      }
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="Cidade"
                      style={{
                        backgroundColor: "#fff",
                        width: "200px",
                        marginRight: 10,
                      }}
                      value={modalInfos.address.city}
                      onChange={(e) =>
                        setModalInfos({
                          ...modalInfos,
                          address: {
                            ...modalInfos.address,
                            city: e.target.value,
                          },
                        })
                      }
                      margin="normal"
                    />
                    <TextField
                      label="Complemento"
                      value={modalInfos.address.complement}
                      style={{
                        backgroundColor: "#fff",
                        width: "200px",
                      }}
                      onChange={(e) =>
                        setModalInfos({
                          ...modalInfos,
                          address: {
                            ...modalInfos.address,
                            complement: e.target.value,
                          },
                        })
                      }
                      margin="normal"
                    />
                  </LineDivBox>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "category"}
                onChange={handleChange("category")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="categorybh-content"
                  id="categorybh-header"
                  style={{
                    backgroundColor: "#e7e7e7",
                  }}
                >
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    Categorias
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "30px",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <DefaultButton
                      style={{
                        width: "200px",
                        margin: 0,
                      }}
                      onClick={addCategory}
                    >
                      Nova Categoria
                    </DefaultButton>
                  </div>

                  <StaticTable
                    rows={categories}
                    isLoading={isLoadingGetCategory}
                    columns={columnsCategories}
                    height="300px"
                    rowSize="small"
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </LineDivBox>

          <Box mt={2}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <DefaultButton
                style={{ marginRight: 1, backgroundColor: "#8f1515" }}
                onClick={() => closeModal()}
              >
                Cancelar
              </DefaultButton>
              <DefaultButton
                onClick={() =>
                  modalInfos.id ? updateClinic() : createClinic()
                }
              >
                Salvar
              </DefaultButton>
            </Stack>
          </Box>
        </ModalEditClinicBox>
      </Modal>
      <Modal
        open={openModalDeleteClinic}
        onClose={() => setOpenModalDeleteClinic(false)}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Confirmar Exclusão
          </Typography>
          <Typography gutterBottom>
            Tem certeza que deseja excluir a categoria{" "}
            <b>{deleteClinicProps.name}</b>?
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <DefaultButton
              onClick={() => setOpenModalDeleteClinic(false)}
              style={{ marginRight: 1 }}
            >
              Cancelar
            </DefaultButton>
            <DefaultButton onClick={() => deleteClinic()}>
              Excluir
            </DefaultButton>
          </Box>
        </Box>
      </Modal>
    </DefaultTemplate>
  );
};

export default Clinicas;
