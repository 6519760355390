import { IconButton } from "@mui/material";
import React from "react";
import { Paper, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ContainerTop, FilterStyle } from "./style";
import Button from "../../components/buttom";
import "./style.css";
import { ReportsApi } from "../../services/modules/reports";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DynamicTable } from "../../components/dynamic-table";
import DefaultTemplate from "../../templates/default";
import { FilterProps } from "./filter";
import PopoverFilter from "./filter";

export interface LaudosProps {
  isShowingSidebar?: boolean;
}
const Laudos: React.FC<LaudosProps> = ({ isShowingSidebar }) => {
  const [patient, setPatient] = useState("");
  /* const [getReports, { isLoading: isLoadingGetUser }] =
    useGetUserReportsMutation();
  const [countPages, { isLoading: isLoadingCountPages }] =
    useCountPagesMutation(); */
  const [isLoadingGetUser, setIsLoadingGetUser] = useState(false);
  const [isLoadingCountPages, setIsLoadingCountPages] = useState(false);
  const [filters, setFilters] = useState<FilterProps>({
    suspectDiagnosis: "",
    examDate: undefined,
    tutor: "",
    clinic: "",
    breedType: "",
  });
  const [reports, setReports] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const columns = [
    {
      id: "search",
      label: "",
      style: {
        width: "30px",
      },
    },
    {
      id: "team",
      label: "Pertence",
    },
    {
      id: "tutor",
      label: "Tutor",
    },
    {
      id: "patient",
      label: "Paciente",
    },
    {
      id: "clinic",
      label: "Clinica",
    },
    {
      id: "breedType",
      label: "Espécie",
    },
    {
      id: "suspectDiagnosis",
      label: "Suspeita",
    },
    {
      id: "examDate",
      label: "Data",
    },
  ];
  const [debouncedTerm, setDebouncedTerm] = useState(patient);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(patient + "a");
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [patient]);

  useEffect(() => {
    if (debouncedTerm) {
      reloadSearch();
    }
  }, [debouncedTerm]);

  const formatReports = (reports: any[]): any => {
    return reports.map((report: any) => {
      const examDate = new Date(report.examDate);
      return {
        ...report,
        examDate: `${examDate.toLocaleTimeString()} - ${examDate.toLocaleDateString()}`,
        search: (
          <SearchIcon
            style={{ cursor: "pointer", width: "30px" }}
            onClick={() => openReport(report.id)}
          />
        ),
        team: report.idTeam ? (
          <span style={{ color: "blue" }}>Equipe</span>
        ) : (
          <span style={{ color: "green" }}>Pessoal</span>
        ),
      };
    });
  };

  const getReportsFilter = () => {
    const reportsFilter: any = {
      patient,
    };
    if (
      filters.examDate ||
      filters.suspectDiagnosis ||
      filters.tutor ||
      filters.breedType ||
      filters.clinic
    ) {
      reportsFilter.examDate = filters.examDate?.toISOString();
      reportsFilter.suspectDiagnosis = filters.suspectDiagnosis;
      reportsFilter.tutor = filters.tutor;
      reportsFilter.breedType = filters.breedType;
      reportsFilter.clinic = filters.clinic;
    }
    return reportsFilter;
  };

  const findReports = () => {
    setIsLoadingGetUser(true);
    ReportsApi.getUserReports({
      ...getReportsFilter(),
      page,
      limit: rowsPerPage,
    })
      .then((data: any[]) => {
        setReports(formatReports(data));
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingGetUser(false);
      });
  };

  const openReport = (id: string) => {
    history.push(`/laudo/${id}`);
  };

  const openNewReport = () => {
    history.push("/novo-laudo");
  };

  const requestCountPages = () => {
    setIsLoadingCountPages(true);
    ReportsApi.countPages({
      ...getReportsFilter(),
      page,
      limit: rowsPerPage,
    })
      .then((data: any) => {
        setPageCount(data.pages);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingCountPages(false);
      });
  };
  const reloadSearch = () => {
    requestCountPages();
    findReports();
  };

  useEffect(() => {
    if (isLoadingCountPages) return;
    reloadSearch();
  }, [, rowsPerPage]);
  useEffect(() => {
    if (isLoadingGetUser) return;
    findReports();
  }, [page]);

  return (
    <DefaultTemplate title="Laudos" setContainer={false}>
      {/* Filter */}
      <ContainerTop>
        <FilterStyle>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: "0",
              borderRadius: "10px",
              width: 400,
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.125)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquise por um paciente..."
              value={patient}
              onChange={(e) => setPatient(e.target.value)}
            />
            <IconButton
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={reloadSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <PopoverFilter
            applyFilter={reloadSearch}
            filters={filters}
            setFilters={setFilters}
          />
        </FilterStyle>
        <Button
          style={{ cursor: "pointer", width: "180px", height: "40px" }}
          text={"Criar Laudo"}
          callback={() => openNewReport()}
        />
      </ContainerTop>

      {/* Table */}
      <DynamicTable
        isShowingSidebar={isShowingSidebar}
        rows={reports}
        isLoading={isLoadingGetUser || isLoadingCountPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        columns={columns}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
      />
    </DefaultTemplate>
  );
};

export default Laudos;
