import { styled } from "@mui/material";

export const DropZoneContainer = styled("div")<{
  dragging: boolean;
  canEdit: boolean;
}>((props) => {
  return {
    border: "2px dashed #ccc",
    textAlign: "center",
    cursor: props.canEdit ? "pointer" : "default",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    ...(props.dragging
      ? {
          backgroundColor: "#f0f0f0",
          border: "2px dashed #00923B !important",
        }
      : {}),
  };
});

export const TextoDestacado = styled("span")({
  color: "#00923b",
  fontWeight: "bold",
});
