import React, { useState } from "react";
import { ReportsApi } from "../../services/modules/reports";
import { toast } from "react-toastify";
import { Container, Input, Label, LoginForm } from "./style";
import { RandomBackground } from "../../components/random-background";
import { DefaultButton } from "../../components/default-button";
import { Box, IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
const pawImg = require("./../../assets/images/logo/logoCinza.png");

interface LaudoPasswordProps {
  reportId: string;
  setReport: (report: string) => void;
}
const LaudoPassword = (props: LaudoPasswordProps) => {
  const reportId = props.reportId;
  const [background] = RandomBackground();

  const [password, setPassword] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltip = () => setOpenTooltip(!openTooltip);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    ReportsApi.getClientReport({ id: reportId, password })
      .then((data) => {
        toast.success("Laudo acessado com sucesso!");
        props.setReport(data);
      })
      .catch((e) => {
        toast.error("Credenciais incorretas. Tente novamente.");
      });
  };

  return (
    <Container
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <LoginForm
        onSubmit={handleSubmit}
        sx={{
          width: {
            xs: 300, // theme.breakpoints.up('xs')
            sm: 300, // theme.breakpoints.up('sm')
            md: 400, // theme.breakpoints.up('md')
            lg: 500, // theme.breakpoints.up('lg')
            xl: 500, // theme.breakpoints.up('xl')
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            justifyContent={"center"}
            component="img"
            sx={{
              width: {
                xs: 200, // theme.breakpoints.up('xs')
                sm: 200, // theme.breakpoints.up('sm')
                md: 250, // theme.breakpoints.up('md')
                lg: 250, // theme.breakpoints.up('lg')
                xl: 250, // theme.breakpoints.up('xl')
              },
            }}
            boxShadow={0}
            src={pawImg}
          />
        </div>
        <Label htmlFor="reportId">Identificador do Laudo:</Label>
        <Input type="text" id="reportId" value={reportId} readOnly={true} />
        <div>
          <Label
            htmlFor="password"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <span>Senha :</span>
            <Tooltip
              style={{
                height: "1px",
                paddingTop: "10px",
              }}
              title="Caso não tenha a senha, entre em contato com o responsável pelo laudo."
              onClick={handleTooltip}
              open={openTooltip}
            >
              <IconButton>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </Label>
          <Input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            /* style={{ width: "calc(100% - 40px)" }} */
          />
        </div>
        <DefaultButton type="submit">Acessar Laudo</DefaultButton>
      </LoginForm>
    </Container>
  );
};

export default LaudoPassword;
