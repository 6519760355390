import { styled } from "@mui/material";

export const Container = styled("div")({
  width: "100%",
  height: "100%",
  minWidth: "640px",
  marginBottom: "50px",
  backgroundColor: "#fff",
  position: "relative",
  padding: "2%",
  display: "flex",
  flexDirection: "column",
  border: "2px outset #cbc9c9",
  borderRight: "2px solid #cbc9c9",
  borderBottom: "2px solid #cbc9c9",
  borderTopRightRadius: "6px",
  borderBottomLeftRadius: "6px",
  borderBottomRightRadius: "6px",
  borderTopLeftRadius: "0px",
});
