import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LaudoPassword from "../laudo-password";
import { ReportsApi } from "../../services/modules/reports";
import { toast } from "react-toastify";
import Laudo from "../laudo";
import DefaultTemplate from "../../templates/default";
import { setLoadingExternally } from "../../providers/loading.provider";

interface ParamProps {
  reportId: string;
}
const Report = () => {
  const [reportNeedPassword, setReportNeedPassword] = useState(null);

  const [report, setReport] = useState(null as any);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.user);
  const { reportId } = useParams<ParamProps>();
  useEffect(() => {
    setLoadingExternally(true);
    ReportsApi.reportExists(reportId)
      .then((data: any) => {
        if (!data.exists) {
          toast.error(
            "Laudo não encontrado ou usuario não autorizado a acessar esse laudo!"
          );
          history.push("/laudos");
        } else {
          setReportNeedPassword(data.needPassword);
        }
      })
      .catch(() => {})
      .finally(() => setLoadingExternally(false));

    if (user) {
      ReportsApi.getUserReport(reportId)
        .then((data) => {
          setReport(data);
        })
        .catch((e) => {
          toast.error(
            "Laudo não encontrado ou usuario não autorizado a acessar esse laudo!"
          );
        });
    }
  }, []);
  useEffect(() => {
    if (reportNeedPassword === false) {
      ReportsApi.getClientReport({ id: reportId })
        .then((data: any) => {
          setReport(data);
        })
        .catch(() => {});
    }
  }, [reportNeedPassword]);
  if (user && !report && reportNeedPassword === null) {
    return (
      <DefaultTemplate>
        <div style={{ height: "80vh" }}></div>
      </DefaultTemplate>
    );
  }
  if (reportNeedPassword === true && !report) {
    return user ? (
      <DefaultTemplate title="Laudo encontrado">
        <LaudoPassword
          reportId={reportId}
          setReport={setReport}
        ></LaudoPassword>
      </DefaultTemplate>
    ) : (
      <LaudoPassword reportId={reportId} setReport={setReport}></LaudoPassword>
    );
  }
  return report && <Laudo externalClient={!user} report={report}></Laudo>;
};

export default Report;
