import { createTheme, Shadows } from "@mui/material";

export const theme = createTheme({
  /* shadows: Array(25).fill("none") as Shadows, */
  palette: {
    primary: {
      light: "#2FB199",
      contrastText: "#FFFFFF",
      dark: "#1A6154",
      main: "#207868",

      "50": "#E3F7F4",
      "100": "#C7F0E8",
      "200": "#8EE1D2",
      "300": "#56D2BB",
      "400": "#2FB199",
      "500": "#207868",
      "600": "#1A6154",
      "700": "#13483F",
      "800": "#0D302A",
      "900": "#061815",
    },
    secondary: {
      main: "#65a683",
    },
    error: {
      main: "#eb5757",
    },
    text: {
      primary: "#474242",
      secondary: "#585252",
    },
    background: {
      default: "#F6F6F6",
    },
  },
});
