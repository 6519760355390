import React, { useState } from "react";
import { ScreenStyles, Title, Content, MainPanel } from "./style";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPicture } from "../../services/modules/user";
import { setPicureAction } from "../../store/modules/user/reducer";

import ContentContainer from "../../components/content_container";
import Sidebar from "../../components/sidebar";
import { toast } from "react-toastify";

interface DefaultTemplateProps {
  title?: string;
  children?: React.ReactNode;
  setContainer?: boolean;
  mainPanelStyle?: any;
}

const DefaultTemplate: React.FC<DefaultTemplateProps> = ({
  title,
  children,
  setContainer,
  mainPanelStyle,
}) => {
  const [showSidebar, setShowSidebar] = React.useState(true);
  const user = useSelector((state: any) => state.user.user);
  const [isLoading, isLoadingPicture] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO: Verificar se já possui um objeto user no estado local e se for o caso não precisa fazer chamada de api.
    // TODO: Entender por que o token salvo no localStorage está diferente do token usado para fazer as requisições de usuário e foto de perfil.
    if (!user.image) {
      getPicture()
        .then((data: any) => {
          dispatch(setPicureAction(data));
        })
        .catch(() => {
          toast.error("Erro ao buscar foto de perfil");
        });
    }
  }, [user]);

  return (
    <ScreenStyles>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        user={{
          email: user.email,
          name: user.name,
          avatar: user.image,
          isAdmin: user.isAdmin,
          isClient: user.isClient,
        }}
      />

      <MainPanel
        isShowingSidebar={showSidebar}
        style={mainPanelStyle ? mainPanelStyle : {}}
      >
        <Title isShowingSidebar={showSidebar}>{title ?? ""}</Title>
        <Content isShowingSidebar={showSidebar}>
          {setContainer === false ? (
            children
          ) : (
            <ContentContainer>{children}</ContentContainer>
          )}
        </Content>
      </MainPanel>
    </ScreenStyles>
  );
};

export default DefaultTemplate;

/* <ScreenStyles>
      <Sidebar userImageUrl={user.image} userName={user.name} />

      <MainPanel>
        <Title>{title}</Title>
        <Content>
          <ContentContainer>{children}</ContentContainer>
        </Content>
      </MainPanel>
    </ScreenStyles> */
