import { IHttpClient } from "../interfaces/base";
import { IUserService } from "../interfaces/IUser";
import { UserType } from "../models/user";

export class UserService implements IUserService {
  private httpClient: IHttpClient;
  private baseUrl: string = "users/";
  private static instance: UserService;

  private constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  public static getInstance(httpClient: IHttpClient): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService(httpClient);
    }

    return UserService.instance;
  }

  getUser(): Promise<UserType[]> {
    return this.httpClient.get<UserType[]>(this.baseUrl);
  }

  getAUser(id: number): Promise<UserType> {
    return this.httpClient.get<UserType>(`${this.baseUrl}${id}`);
  }

  createUser(user: UserType): Promise<UserType> {
    return this.httpClient.post<UserType>(this.baseUrl, user);
  }

  updateUser(user: UserType): Promise<UserType> {
    return this.httpClient.put<UserType>(`${this.baseUrl}update`, user);
  }

  updateProfilePicture(profilePicture: any): Promise<any> {
    return this.httpClient.put(
      `${this.baseUrl}update_profile_picture`,
      profilePicture
    );
  }

  updateLogoPicture(logo: any): Promise<any> {
    return this.httpClient.put(`${this.baseUrl}update-logo`, logo);
  }

  updateLogoBackgroundPicture(logoBackground: any): Promise<any> {
    return this.httpClient.put(
      `${this.baseUrl}update-logo-background`,
      logoBackground
    );
  }

  updateSignaturePicture(signature: any): Promise<any> {
    return this.httpClient.put(`${this.baseUrl}update-signature`, signature);
  }

  deleteUser(id: number): Promise<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}${id}`);
  }
}
