import React, { useEffect, useRef, useState } from "react";
import { ExternalImageDiv, InsideImageDiv, StyledImage } from "./styles";
import { toast } from "react-toastify";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import { DefaultButton } from "../../../components/default-button";
const trashBinIcon = require("./../../../assets/images/icons/trash-bin.png");

interface PictureSectionProps {
  title: string;
  name: string;
  previewImage: string | null;
  image: any;
  setSelectedImage: React.Dispatch<React.SetStateAction<any | null>>;
  setPreviewImage: React.Dispatch<React.SetStateAction<string | null>>;
  boxRight?: boolean;
  isSquare?: boolean;
  deleteFnct?: (name: string) => void;
}
export const PictureSection = ({
  image,
  previewImage,
  title,
  name,
  setSelectedImage,
  setPreviewImage,
  boxRight,
  isSquare,
  deleteFnct,
}: PictureSectionProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState(false);
  const handleButtonClick = () => {
    inputRef.current?.click(); // Trigga o clique no input de arquivo
  };

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0];

    if (file) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validTypes.includes(file.type)) {
        toast.error("Apenas arquivos .png ou .jpeg são permitidos.");
        return;
      }

      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error("O arquivo deve ter no máximo 2 MB.");
        return;
      }

      /* const formData = new FormData();
      formData.append(fileNameForm, file);
      setUploadIMageFoto(formData); */

      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div>
      <span
        style={{
          color: "#000000",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        {title} <p style={{ fontSize: "10px", color: "#666666" }}>(opcional)</p>
      </span>
      <ExternalImageDiv
        style={{
          ...(boxRight
            ? { display: "flex", flexDirection: "row" }
            : {
                alignItems: "flex-start",
              }),
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ position: "relative", width: "150px", height: "150px" }}
          >
            <StyledImage
              isSquare={isSquare}
              src={previewImage ?? image}
              alt="Imagem de Perfil"
            />
            <InsideImageDiv
              onMouseEnter={(e) => (e.currentTarget.style.opacity = "1")}
              onMouseLeave={(e) => (e.currentTarget.style.opacity = "0")}
              onClick={() => handleButtonClick()}
            >
              Editar imagem
            </InsideImageDiv>
          </div>
          <input
            type="file"
            ref={inputRef}
            accept=".png, .jpeg, .jpg"
            style={{ display: "none" }}
            onChange={(e) => handleImageUpload(e)}
          />
          {!boxRight && (
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              hidden={Boolean(deleteFnct)}
              startIcon={<DeleteOutline />}
              style={{
                backgroundColor: "#FFA4A4",
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "none",
                height: "40px",
                marginLeft: "10px",
                width: "105px",
                marginTop: "70px",
              }}
              onClick={() => setOpenModal(true)}
            >
              Deletar
            </Button>
          )}
        </div>
        <div>
          <div
            style={{
              backgroundColor: "#92EFBD",
              padding: "3% 4% 3% 0%",
              borderRadius: "5px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            <span
              style={{
                paddingLeft: "20px",
                display: "block",
                marginBottom: "5px",
              }}
            >
              Como aceitamos sua {name}:
            </span>
            <ul style={{ paddingLeft: "40px", margin: "0" }}>
              <li>No formato .PNG ou .JPEG</li>
              <li>Com o tamanho de até 2 MB</li>
            </ul>
          </div>
          {boxRight && (
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              hidden={Boolean(deleteFnct)}
              startIcon={<DeleteOutline />}
              style={{
                backgroundColor: "#FFA4A4",
                color: "#FFFFFF",
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "10px",
                textTransform: "none",
              }}
              onClick={() => setOpenModal(true)}
            >
              Deletar
            </Button>
          )}
        </div>
      </ExternalImageDiv>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40vw",
            height: "30vh",
            maxWidth: "900px",
            minWidth: "300px",
            minHeight: "260px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            src={trashBinIcon}
            sx={{
              width: "4vw",
              minWidth: "50px",
              height: "auto",
              borderRadius: 4,
            }}
          />

          <Typography variant="h6" component="h2" gutterBottom>
            Confirmar Exclusão
          </Typography>
          <Typography gutterBottom>
            Tem certeza que deseja excluir o usuário <b>{name}</b>?
          </Typography>
          <Box mt={2}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <DefaultButton
                onClick={() => setOpenModal(false)}
                style={{
                  color: "#585252",
                  backgroundColor: "#D9D9D9",
                  width: "40%",
                }}
              >
                Cancelar
              </DefaultButton>
              {deleteFnct && (
                <DefaultButton
                  onClick={() => {
                    deleteFnct(name);
                    setOpenModal(false);
                  }}
                  style={{
                    color: "#FFFFFF",
                    backgroundColor: "#FFA4A4",
                    width: "40%",
                  }}
                >
                  Excluir
                </DefaultButton>
              )}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
