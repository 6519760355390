import { userSlice } from "./modules/user/reducer";
import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../services/apiSlice";
import { configureApi } from "../services/modules/reports-axios";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    user: userSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export const api = configureApi(store.dispatch, store.getState);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
