import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL, // Servidor que eu fiz de exemplo, so pra testar as requests
  timeout: 40000,
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token).access_token}`;
  }
  return config;
});

export default instance;
