import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Paper, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ContainerTop, FilterStyle, ModalCustom } from "./style";
import Button from "../../components/buttom";
import "./style.css";
import { useEffect, useState } from "react";
import DefaultTemplate from "../../templates/default";
import { StaticTable } from "../../components/static-table";
import { DeleteOutline, Edit } from "@mui/icons-material";
import { DefaultButton } from "../../components/default-button";
import {
  createCategoryApi,
  deleteCategoryApi,
  getCategories,
  updateCategoryApi,
} from "../../services/modules/category";
import { toast } from "react-toastify";
import { setLoadingExternally } from "../../providers/loading.provider";
const trashBinIcon = require("./../../assets/images/icons/trash-bin.png");

interface ModalCategoriaInfo {
  id: string;
  name: string;
  value: string | number;
  team: boolean;
}
const Categorias = ({ isAdmin }: { isAdmin: boolean }) => {
  const [categoryName, setCategoryName] = useState("");
  const [modalInfos, setModalInfos] = useState<ModalCategoriaInfo>({
    id: "",
    name: "",
    value: 0,
    team: false,
  });
  const [deleteCategoryProps, setDeleteCategoryProps] = useState({
    id: "",
    name: "",
  });
  const [openModalDeleteCategory, setOpenModalDeleteCategory] = useState(false);
  const [openModalCreateCategory, setOpenModalCreateCategory] = useState(false);
  const [isLoadingGetCategory, setIsLoadingGetCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const columns = [
    {
      id: "edit",
      label: "",
      style: {
        width: "30px",
      },
    },
    {
      id: "name",
      label: "Nome",
    },
    {
      id: "value",
      label: "Valor",
    },
    {
      id: "team",
      label: "Visualização",
    },
    {
      id: "delete",
      label: "",
      style: {
        width: "30px",
      },
    },
  ];
  const [debouncedTerm, setDebouncedTerm] = useState(categoryName);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(categoryName + "a");
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [categoryName]);

  useEffect(() => {
    if (debouncedTerm) {
      reloadSearch();
    }
  }, [debouncedTerm]);

  const closeModal = () => {
    setOpenModalCreateCategory(false);
    setModalInfos({
      id: "",
      name: "",
      team: false,
      value: 0,
    });
  };

  const formatCategories = (categories: any[]): any => {
    return categories.map((category: any) => {
      return {
        ...category,
        value: `R$ ${category.value.toString().replace(".", ",")}`,
        team: category.idTeam ? (
          <span style={{ color: "blue" }}>Equipe</span>
        ) : (
          <span style={{ color: "green" }}>Pessoal</span>
        ),
        edit: (
          <Edit
            style={{ cursor: "pointer", width: "30px" }}
            onClick={() => {
              setModalInfos({
                id: category.id,
                name: category.name,
                team: category.idTeam,
                value: category.value.toString().replace(".", ","),
              });
              setOpenModalCreateCategory(true);
            }}
          />
        ),
        delete: (
          <DeleteOutline
            sx={{
              cursor: "pointer",
              width: "30px",
              color: "#FFA4A4",
              "&:hover": {
                color: "#FF7A7A",
              },
            }}
            onClick={() => {
              setDeleteCategoryProps({
                id: category.id,
                name: category.name,
              });
              setOpenModalDeleteCategory(true);
            }}
          />
        ),
      };
    });
  };
  const findCategories = () => {
    setIsLoadingGetCategory(true);
    getCategories(categoryName)
      .then((data: any[]) => {
        setCategories(formatCategories(data));
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingGetCategory(false);
      });
  };

  const reloadSearch = () => {
    findCategories();
  };

  const createCategory = () => {
    setLoadingExternally(true);
    createCategoryApi({
      name: modalInfos.name,
      team: modalInfos.team,
      value: modalInfos.value,
    })
      .then(() => {
        closeModal();
        reloadSearch();
        toast.success("Categoria criada com sucesso!");
      })
      .catch(() => {
        setOpenModalCreateCategory(false);
      })
      .finally(() => setLoadingExternally(false));
  };

  const updateCategory = () => {
    setLoadingExternally(true);
    updateCategoryApi({
      id: modalInfos.id,
      name: modalInfos.name,
      team: modalInfos.team,
      value: modalInfos.value,
    })
      .then(() => {
        closeModal();
        reloadSearch();
        toast.success("Categoria atualizada com sucesso!");
      })
      .catch(() => {
        setOpenModalCreateCategory(false);
      })
      .finally(() => setLoadingExternally(false));
  };

  const deleteCategory = () => {
    setLoadingExternally(true);
    deleteCategoryApi(deleteCategoryProps.id)
      .then(() => {
        setOpenModalDeleteCategory(false);
        toast.success("Categoria excluída com sucesso!");
        reloadSearch();
      })
      .finally(() => setLoadingExternally(false));
  };

  return (
    <DefaultTemplate title="Categorias" setContainer={false}>
      {/* Filter */}
      <ContainerTop>
        <FilterStyle>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              border: "0",
              borderRadius: "10px",
              width: 400,
              boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.125)",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquise por uma categoria..."
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <IconButton
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={reloadSearch}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </FilterStyle>
        <Button
          style={{ cursor: "pointer", width: "180px", height: "40px" }}
          text={"Nova Categoria"}
          callback={() => setOpenModalCreateCategory(true)}
        />
      </ContainerTop>

      {/* Table */}
      <StaticTable
        rows={categories}
        isLoading={isLoadingGetCategory}
        columns={columns}
      />

      {/* Modal */}
      <ModalCustom open={openModalCreateCategory} onClose={() => closeModal()}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {modalInfos.id ? "Editar Categoria" : "Cadastro da Categoria"}
          </Typography>
          <TextField
            fullWidth
            label="Nome"
            value={modalInfos.name}
            onChange={(e) =>
              setModalInfos({
                ...modalInfos,
                name: e.target.value,
              })
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="Preço"
            type="text"
            value={modalInfos.value}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            onChange={(e) => {
              const value = e.target.value;
              if (
                value === "" ||
                (/^\d*[.,]?\d*$/.test(value) && parseFloat(value) >= 0)
              ) {
                setModalInfos({
                  ...modalInfos,
                  value: value,
                });
              }
            }}
            margin="normal"
          />

          {isAdmin ? (
            <>
              <Divider orientation="horizontal" />
              <Typography variant="subtitle1" gutterBottom>
                Tipo de Categoria
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={modalInfos.team}
                    onChange={(e) =>
                      setModalInfos({
                        ...modalInfos,
                        team: e.target.checked,
                      })
                    }
                  />
                }
                label="Equipe"
              />
            </>
          ) : null}
          <Box mt={2}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <DefaultButton
                style={{ marginRight: 1, backgroundColor: "#8f1515" }}
                onClick={() => closeModal()}
              >
                Cancelar
              </DefaultButton>
              <DefaultButton
                onClick={() =>
                  modalInfos.id ? updateCategory() : createCategory()
                }
              >
                Salvar
              </DefaultButton>
            </Stack>
          </Box>
        </Box>
      </ModalCustom>
      <ModalCustom
        open={openModalDeleteCategory}
        onClose={() => setOpenModalDeleteCategory(false)}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40vw",
            height: "30vh",
            maxWidth: "900px",
            minWidth: "300px",
            minHeight: "260px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            src={trashBinIcon}
            sx={{
              width: "4vw",
              minWidth: "50px",
              height: "auto",
              borderRadius: 4,
            }}
          />

          <Typography variant="h6" component="h2" gutterBottom>
            Confirmar Exclusão
          </Typography>
          <Typography gutterBottom>
            Tem certeza que deseja excluir a categoria{" "}
            <b>{deleteCategoryProps.name}</b>?
          </Typography>
          <Box mt={2}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <DefaultButton
                onClick={() => setOpenModalDeleteCategory(false)}
                style={{
                  color: "#585252",
                  backgroundColor: "#D9D9D9",
                  width: "40%",
                }}
              >
                Cancelar
              </DefaultButton>
              <DefaultButton
                onClick={() => deleteCategory()}
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#FFA4A4",
                  width: "40%",
                }}
              >
                Excluir
              </DefaultButton>
            </Stack>
          </Box>
        </Box>
      </ModalCustom>
    </DefaultTemplate>
  );
};

export default Categorias;
