import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import { RandomBackground } from "../random-background";
const logo = require("./../../assets/images/logo/logoCinza.png");

export interface AuthScreenProps {
  children: React.ReactNode;
  onSubmit?: (e?: any) => void;
}
const AuthScreen = (props: AuthScreenProps) => {
  const [background] = RandomBackground();
  return (
    <Grid container maxWidth={6000} style={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          sx={{
            width: "80%",
          }}
        >
          <form
            onSubmit={props.onSubmit}
            style={{
              width: "100%",
              marginTop: "-20%",
              display: "flex",
              /* justifyContent: "center",
              alignContent: "space-around",
              flexWrap: "wrap", */
              placeContent: "space-around center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              justifyContent={"center"}
              component="img"
              sx={{
                boxShadow: 0,
                /* height: 100,
                width: 100, */
                /* maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 }, */
                width: {
                  xs: 200, // theme.breakpoints.up('xs')
                  sm: 200, // theme.breakpoints.up('sm')
                  md: 250, // theme.breakpoints.up('md')
                  lg: 300, // theme.breakpoints.up('lg')
                  xl: 400, // theme.breakpoints.up('xl')
                },
              }}
              src={logo}
            />
            {props.children}
          </form>
        </Box>
      </Grid>
      <Grid
        item
        xs={0}
        sm={4}
        md={6}
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Grid>
    </Grid>
  );
};

export default AuthScreen;
