import React from "react";
import { CustomButton } from "./style";

interface ButtomProps {
  text: string;
  callback: () => void;
  style?: object;
  hidden?: boolean;
  options?: {
    backgroundColor?: string;
    hoverBackgroundColor?: string;
  };
}

const Buttom: React.FC<ButtomProps> = ({
  text,
  callback,
  style,
  hidden,
  options,
}) => {
  return (
    <CustomButton
      options={options}
      onClick={callback}
      style={{ ...style }}
      hidden={hidden}
    >
      {text}
    </CustomButton>
  );
};

export default Buttom;
