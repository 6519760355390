import { styled } from "@mui/material";

const ScreenStyles = styled("div")({
  backgroundColor: "#F6F6F6",
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "row",
});

const Title = styled("div", {
  shouldForwardProp: (propName) => propName !== "isShowingSidebar",
})<any>(({ isShowingSidebar }) => ({
  position: "relative",
  paddingTop: "5px",
  top: "2vh",
  height: "40px",
  fontWeight: "bold",
  fontSize: "24px",
  left: isShowingSidebar ? "7%" : "10px",
}));

const Content = styled("div", {
  shouldForwardProp: (propName) => propName !== "isShowingSidebar",
})<any>(({ isShowingSidebar }) => ({
  position: "relative",
  top: "2vh",
  left: isShowingSidebar ? "7%" : "10px",
}));

const MainPanel = styled("div", {
  shouldForwardProp: (props) => props !== "isShowingSidebar",
})<any>(({ theme, isShowingSidebar }) => ({
  width: isShowingSidebar
    ? "calc(100% - (240px + 10%))"
    : `calc(100% - (${theme.spacing(8)} + 1px + 15px))`,
  backgroundColor: "#F6F6F6",
  height: "100vh",
  position: "relative",
  top: "0",
}));
export { ScreenStyles, Title, Content, MainPanel };
