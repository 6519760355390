import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import {
  Dialog,
  Grid,
  IconButton,
  ImageList,
  ImageListItemBar,
} from "@mui/material";
import { DropzoneImageListElement } from "./style";

export interface ImageToShow {
  base: string;
  title?: string;
}

export type FileImage = File & ImageToShow;

export interface DropzoneImageListProps {
  images: FileImage[];
  width: number;
  setImages: (images: FileImage[]) => void;
  onRemove?: (fileName: string) => void;
}

export const DropzoneImageList = ({
  images,
  width,
  setImages,
  onRemove,
}: DropzoneImageListProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<FileImage>();
  const handleCloseDialog = (e) => {
    disablePropagation(e);
    setOpenDialog(false);
  };

  const disablePropagation = (e) => {
    e.stopPropagation();
  };

  const removeFile = (fileName) => {
    setImages(images.filter((file) => file.name !== fileName));
    onRemove?.call(null, fileName);
  };

  const downloadFile = (fileName: string) => {
    const file: any = images.find((file) => file.name === fileName);
    if (file) {
      if (file.base) {
        const link = document.createElement("a");
        link.href = file.base;
        link.download = file.name;

        // Adicione o link ao DOM
        document.body.appendChild(link);

        // Clique no link para iniciar o download
        link.click();

        // Remova o link do DOM
        document.body.removeChild(link);
      } else {
        const reader = new FileReader();

        reader.onload = (e) => {
          const link = document.createElement("a");
          link.href = e.target!.result as string;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const previewImage = (file: FileImage) => {
    setSelectedItem(file);
    setOpenDialog(true);
  };
  return (
    <>
      <ImageList
        cols={width > 900 ? 4 : width > 600 ? 3 : width > 400 ? 2 : 1}
        style={{
          maxHeight: "480px",
        }}
      >
        {images.map((item, index) => (
          <DropzoneImageListElement key={index} onClick={disablePropagation}>
            <img
              src={item.base}
              alt={item.name}
              loading="lazy"
              style={{
                height: "150px",
              }}
              onClick={() => previewImage(item)}
            />
            <ImageListItemBar
              style={{ display: "none" }}
              title={item.name}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.name}`}
                >
                  <DownloadForOfflineOutlinedIcon
                    onClick={() => downloadFile(item.name)}
                  />

                  <DeleteIcon
                    style={{ marginLeft: 10 }}
                    onClick={() => removeFile(item.name)}
                  />
                </IconButton>
              }
            />
          </DropzoneImageListElement>
        ))}
      </ImageList>
      <Dialog
        onClose={handleCloseDialog}
        onClick={disablePropagation}
        open={openDialog}
      >
        {selectedItem ? (
          <img src={selectedItem.base} alt={selectedItem.name} loading="lazy" />
        ) : (
          <span>CARREGANDO ...</span>
        )}
      </Dialog>
    </>
  );
};
