import { styled, TextField, Typography } from "@mui/material";

export const ForgotPasswordTitle = styled(Typography)({
  textAlign: "center",
  fontWeight: "bold",
});

export const ForgotPasswordSubTitle = styled(Typography)({
  paddingTop: "20px",
  textAlign: "center",
  color: "#585252",
  marginBottom: "30px",
});

export const EmailField = styled(TextField)({
  width: "100%",
  marginBottom: "10px",
  marginTop: "10px",
});
