import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { LoadingCircular } from "../loading_circular";
import { TableBox, TableHeaderColumn } from "./styles";

export function DynamicTable({
  rows,
  isLoading,
  rowsPerPage,
  setRowsPerPage,
  columns,
  pageCount,
  page,
  setPage,
  isShowingSidebar,
}: any) {
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  const numberOfRows = Math.round(rowsPerPage * pageCount);

  return (
    <TableBox>
      <TableContainer
        style={{
          height: "calc(100% - 52px)",
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableHeaderColumn
                  key={column.id}
                  align={column.align}
                  style={
                    index === 0
                      ? {
                          borderRadius: "10px 0 0 10px",
                        }
                      : index === columns.length - 1
                      ? { borderRadius: "0 10px 10px 0" }
                      : {}
                  }
                >
                  {column.label}
                </TableHeaderColumn>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow hover role="checkbox" tabIndex={-1} key="loading">
                <TableCell className="cellLoading" colSpan={5} align="center">
                  <LoadingCircular isLoading={isLoading} />
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            ...(column.bodyRowStyle || column.style),
                            color: "grey",
                          }}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        style={{
          color: "grey",
        }}
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={numberOfRows}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count}`
        }
      />
    </TableBox>
  );
}
