import React, { createContext, useContext } from "react";
import { UserService } from "../services/implementation/User";
import { AxiosHttpClient } from "./implementation/base";
import instance from "../services/api_instances/laudos_vet";

const UserServiceContext = createContext<UserService | null>(null);

export const useUserService = (): UserService => {
    const userContext = useContext(UserServiceContext);
    if (!userContext) {
        throw new Error("useUserService must be used within an ApiServiceProvider.");
    }
    return userContext;
}

interface ServiceProviderProps {
    children: React.ReactNode; 
  }

export const ServiceProvider: React.FC<ServiceProviderProps> = ({ children }) => {
    const httpClient = new AxiosHttpClient(instance);
    const userServiceInstance = UserService.getInstance(httpClient);

    return (
        <UserServiceContext.Provider value={userServiceInstance}>
            {children}
        </UserServiceContext.Provider>
    );
}
