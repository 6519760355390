import React from "react";
import "./styles.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { LoadingCircular } from "../loading_circular";
import { TableBox, TableHeaderColumn } from "./styles";

interface StaticTableProps {
  rows: any[];
  isLoading: boolean;
  columns: Record<string, any>;
  height?: string;
  rowSize?: "small" | "medium";
}
export function StaticTable({
  rows,
  isLoading,
  columns,
  height,
  rowSize,
}: StaticTableProps) {
  return (
    <TableBox height={height}>
      <TableContainer
        style={{
          height: height || "calc(100% - 52px)",
        }}
      >
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableHeaderColumn
                  key={column.id}
                  align={column.align}
                  style={
                    index === 0
                      ? {
                          borderRadius: "10px 0 0 10px",
                        }
                      : index === columns.length - 1
                      ? { borderRadius: "0 10px 10px 0" }
                      : {}
                  }
                >
                  {column.label}
                </TableHeaderColumn>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow hover role="checkbox" tabIndex={-1} key="loading">
                <TableCell className="cellLoading" colSpan={5} align="center">
                  <LoadingCircular isLoading={isLoading} />
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ height: rowSize === "small" ? "20px" : null }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            ...(column.bodyRowStyle || column.style),
                            color: "grey",
                            padding: rowSize === "small" ? "2px 16px" : null,
                          }}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </TableBox>
  );
}
