import { api } from "../../store/store";

export const getCategories = async (categoryName: string) => {
  const response = await api.get(
    "/categories",
    categoryName
      ? {
          params: {
            name: categoryName,
          },
        }
      : {}
  );
  return response.data;
};

export const createCategoryApi = async (category: {
  name: string;
  value: number | string;
  team: boolean;
}) => {
  const response = await api.post("/categories", category);
  return response.data;
};

export const updateCategoryApi = async (category: {
  id: string;
  name: string;
  value: number | string;
  team: boolean;
}) => {
  const response = await api.put(`/categories/${category.id}`, category);
  return response.data;
};

export const deleteCategoryApi = async (idCategory: string) => {
  const response = await api.delete(`/categories/${idCategory}`);
  return response.data;
};
