import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Laudos from "../pages/laudos";
import Laudo from "../pages/laudo";

import PrivateRoute from "./privateRoute";
import Report from "../pages/report";
import PerfilUsuario from "../pages/PerfilUsuario";
import RecuperarSenha from "../pages/recuperar-senha";
import ResetarSenhaPage from "../pages/resetar-senha";
import LoginPage from "../pages/login-page";
import Usuarios from "../pages/cadastrar-usuario";
import Categorias from "../pages/categorias";
import Clinicas from "../pages/clinicas";
import Equipe from "../pages/equipe";

export interface RoutesProps {
  userIsLogged: boolean;
  user: any;
}
export default function Routes(props: RoutesProps) {
  const hasClientAccess = props.user?.isAdmin || props.user?.isClient;
  return (
    <Switch>
      <Route path="/login" component={LoginPage} />
      <PrivateRoute path="/laudos" component={Laudos} permissions={["vet"]} />
      <PrivateRoute exact path="/laudos/report" component={Laudos} />
      <PrivateRoute path="/novo-laudo" component={Laudo} />
      <PrivateRoute path="/perfil" component={PerfilUsuario} />
      {props.user?.isAdmin ? (
        <PrivateRoute path="/usuarios" component={Usuarios} />
      ) : null}
      {hasClientAccess ? (
        <PrivateRoute path="/categorias" component={Categorias} />
      ) : null}
      {hasClientAccess ? (
        <PrivateRoute path="/clinicas" component={Clinicas} />
      ) : null}
      {props.user?.isAdmin ? (
        <PrivateRoute path="/equipe" component={Equipe} />
      ) : null}
      <Route path="/laudo/:reportId" component={Report} />
      <Route path="/recuperar-senha" component={RecuperarSenha} />
      <Route path="/resetar-senha" component={ResetarSenhaPage} />
      <Redirect from="/" to={props.userIsLogged ? "/laudos" : "/login"} />
    </Switch>
  );
}
