import { api } from "../../store/store";

const limit = 10;
interface Report {
  idTeam?: string;
  idClinic?: string;
  clinic?: string;
  id?: string;
  tutor: string;
  patient: string;
  age: string;
  breed: string;
  breedType: string;
  reportText: string;
  password: string;
  examDate: string;
  suspectDiagnosis: string;
  sex: string;
  categories: any[];
  vetRequester: string;
  images: File[];
  uploadedImages: any[];
}

const saveReportMapper = (report: Report) => {
  let formData = new FormData();
  report.images?.forEach((image) => {
    formData.append("images", image);
  });

  if (report.id) {
    formData.append("id", report.id);
    formData.append("uploadedImages", JSON.stringify(report.uploadedImages));
  }
  formData.append("tutor", report.tutor);
  formData.append("patient", report.patient);
  formData.append("password", report.password);
  formData.append("age", report.age);
  formData.append("suspectDiagnosis", report.suspectDiagnosis);
  formData.append("sex", report.sex);
  formData.append("breedType", report.breedType);
  formData.append("breed", report.breed);
  formData.append("reportText", report.reportText);
  formData.append("examDate", report.examDate);
  formData.append("vetRequester", report.vetRequester);
  report.idTeam && formData.append("idTeam", report.idTeam);
  report.idClinic && formData.append("idClinic", report.idClinic);
  report.clinic && formData.append("clinic", report.clinic);
  report.categories &&
    formData.append("categories", JSON.stringify(report.categories || []));
  return formData;
};

class Reports {
  getUserReports = async (filter: any) => {
    const response = await api.get("/reports/", {
      params: {
        limit,
        ...filter,
      },
    });
    return response.data;
  };

  countPages = async (filter: any) => {
    const response = await api.get("/reports/count", {
      params: {
        limit,
        ...filter,
      },
    });
    return response.data;
  };

  getUserReport = async (id: string) => {
    const response = await api.get(`/reports/${id}`);
    return response.data;
  };

  getClientReport = async (params: { id: string; password?: string }) => {
    const response = await api.get(`/reports/client/${params.id}`, {
      headers: {
        password: params.password,
      },
    });
    return response.data;
  };

  reportExists = async (id: string) => {
    const response = await api.get(`/reports/${id}/exists`);
    return response.data;
  };

  saveReport = async (report: Report) => {
    const response = await api.post("/reports", saveReportMapper(report));
    return response.data;
  };

  updateReport = async (report: Report) => {
    const response = await api.put("/reports", saveReportMapper(report));
    return response.data;
  };

  downloadPdf = async (id: string) => {
    const response = await api.get(`/reports/pdf/${id}`, {
      headers: {
        Accept: "application/pdf",
      },
      responseType: "blob",
    });
    return response.data;
  };

  getNewInfos = async () => {
    const response = await api.get(`/reports/new-infos`);
    return response.data;
  };

  sendEmail = async (payload: { idReport: string; email: string }) => {
    const response = await api.post(`/reports/send-email`, payload);
    return response.data;
  };
}

export const ReportsApi = new Reports();
