import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePickerCustom } from "./styles";

interface Props {
  label?: string;
  setDateValue: (Date?: Date) => void;
  dateValue?: Date;
  fullWidth?: boolean;
  paddingTop?: string;
}
export default function BasicDatePicker(props: Props) {
  const [date, setDate] = React.useState<dayjs.Dayjs | null>(
    props.dateValue ? dayjs(props.dateValue) : null
  );

  React.useEffect(() => {
    props.setDateValue(date?.toDate());
  }, [date]);

  React.useEffect(() => {
    if (!props.dateValue) {
      setDate(null);
    }
  }, [props.dateValue]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer sx={{ width: "100%" }} components={["DatePicker"]}>
        <DatePickerCustom
          sx={{
            paddingTop: props.paddingTop,
          }}
          fullWidth={props.fullWidth}
          label={props.label}
          format="DD/MM/YYYY"
          disableFuture={true}
          value={date}
          onChange={(newValue) => {
            setDate(newValue);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
