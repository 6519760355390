import React from "react";
import { CircularProgress, styled } from "@mui/material";
export interface LoadingCircularProps {
  isLoading?: boolean;
}
const LoadingCircularCP = styled(CircularProgress)({
  position: "fixed",
  top: "50%",
  left: "50%",
})
export const LoadingCircular = (props: LoadingCircularProps) => {
  return props.isLoading ? <LoadingCircularCP /> : <></>;
};
