import { Paper, styled, TableCell } from "@mui/material";

export const TableBox = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "height",
})<any>(({ height, theme }) => {
  return {
    color: "grey",
    width: "100%",
    height: height ?? "calc(100vh - 20vh)",
    paddingTop: "10px",
    display: "inline-block",
    marginTop: "5px",
    borderRadius: "10px",
    boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.125)",

    borderBottom: "2px solid #ECECEC",
  };
});

export const TableHeaderColumn = styled(TableCell)({
  fontWeight: "bold",
  color: "#474242",
  paddingTop: "5px",
  paddingBottom: "5px",
  backgroundColor: "#F5F5F5",
});
