import { useState, useEffect } from "react";
import { Snackbar, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import Button from "../buttom/index";
const ConsentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  maxWidth: 600,
  width: "100%",
  border: "1px solid black",
  borderRadius: "10px",
}));

export function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent || consent === "false") {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowConsent(false);
  };

  const handleDeny = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowConsent(false);
  };

  return (
    <Snackbar
      open={showConsent}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <ConsentBox>
        <Typography variant="body2">
          Usamos cookies para melhorar sua experiência e manter sua sessão
          ativa. Ao continuar, você concorda com o uso de cookies.
        </Typography>
        <Button
          text="Aceitar"
          callback={handleAccept}
          style={{
            marginLeft: "1.5%",
            fontSize: "14px",
          }}
        />
        <Button
          text="Recusar"
          callback={handleDeny}
          style={{
            marginLeft: "1.5%",
            fontSize: "14px",
            backgroundColor: "#cb5a5a",
          }}
        />
      </ConsentBox>
    </Snackbar>
  );
}
