import { DefaultButtonStyle } from "./styles";

interface DefaultButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
}
export const DefaultButton = (props: DefaultButtonProps) => {
  return (
    <DefaultButtonStyle
      variant="contained"
      color="primary"
      disableElevation
      onClick={props.onClick}
      style={props.style}
      type={props.type}
    >
      {props.children}
    </DefaultButtonStyle>
  );
};
