import { Button, styled } from "@mui/material";

export const DefaultButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "100%",
  margin: "15px 0",
  fontWeight: "bold",
  ":hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));
