import {
  CSSObject,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Theme,
} from "@mui/material";
import { grey } from "@mui/material/colors";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerElement = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
  "& .MuiDrawer-paper": {
    /*     width: 250, */
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    flexWrap: "nowrap",
    boxSizing: "border-box",
    borderRadius: "0 15px 15px 0",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    overflowX: "hidden",
  },
}));

export const DrawerDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "showSidebar",
})<any>((props) => ({
  /* width: props.showSidebar ? "250px" : "100px", */
  color: "grey !important",
}));

export const BottomList = styled(List)({
  /* bottom: "20px",
  display: "table-cell",
  verticalAlign: "bottom" */
  /* "&:hover": {
    backgroundColor: "#F6F6F6",
  }, */
});

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  margin: "20px 0 0 10px",
  width: "30px",
  /* cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F6F6F6",
  }, */
}));

export const ResponsibleListItemButton = styled(ListItemButton, {
  shouldForwardProp(propName) {
    return propName !== "open";
  },
})<any>(({ open }) => ({
  /* minHeight: 48,
  px: 2.5,
  justifyContent: open ? "initial" : "center", */
}));

export const ResponsibleListItemIcon = styled(ListItemButton, {
  shouldForwardProp(propName) {
    return propName !== "open";
  },
})<any>(({ open }) => {
  return {
    minWidth: 0,
    justifyContent: open ? undefined : "center",
    mr: open ? 3 : "auto",
  };
});

export const ListItemTextElement = styled(ListItemText, {
  shouldForwardProp(propName) {
    return propName !== "open";
  },
})<any>(({ open }) => ({
  color: grey[700],
  opacity: open ? 1 : 0,
  width: "100%",
  marginLeft: "10px",
}));
