import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import "./style.css";
import React, { useEffect, useState } from "react";
import ContentContainer from "../../components/content_container";
import DefaultTemplate from "../../templates/default";
import CreatableSelect from "react-select/creatable";
import Select2 from "react-select";

import Button from "../../components/buttom/index";
import { useHistory } from "react-router-dom";
import { ReportsApi } from "../../services/modules/reports";
import { toast } from "react-toastify";
import DropZone from "../../components/dropzone";
import TextEditor from "../../components/text-editor";
import { api } from "../../store/store";
import { setLoadingExternally } from "../../providers/loading.provider";
import { ModalEmail } from "./modal-email";

const Raca = ["Gato", "Cachorro", "Outro"] as const;
type Raca = (typeof Raca)[number];
export interface NovoLaudoProps {
  externalClient: boolean;
  report?: {
    id: string;
    idTeam?: string;
    idClinic?: string;
    userIsOwner: boolean;
    password: string;
    tutor: string;
    patient: string;
    breed: string;
    breedType: string;
    clinic: string;
    age: string;
    examDate: string;
    suspectDiagnosis: string;
    vetRequester: string;
    createdAt: string;
    updatedAt: string;
    sex: string;
    reportText: string;
    categories: {
      id: string;
      idClinic?: string;
      price: number;
    }[];
    images: {
      name: string;
      link: string;
      createdAt: Date;
    }[];
    cannotSeePrivateContent?: boolean;
    vetResponsible: string;
  };
}

interface NewInfos {
  canChooseTeam: boolean;
  team?: {
    id: string;
    name: string;
  };
  clinics: {
    id: string;
    name: string;
    email?: string;
    idTeam?: string;
    fromUser: boolean;
    categories: {
      id: string;
      idClinic: string;
      name: string;
      value: number;
    }[];
  }[];
  categories: {
    id: string;
    fromUser: boolean;
    idTeam?: string;
    name: string;
    value: number;
  }[];
}
const formatDateStringToDateBR = (dateStr: string) => {
  const date = new Date(dateStr);
  const pad = (number) => (number < 10 ? "0" : "") + number;

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  return `${day}/${month}/${year}`;
};

const formatDateToDateTime = (dateStr: string) => {
  const date = new Date(dateStr);
  const pad = (number) => (number < 10 ? "0" : "") + number;

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const Laudo: React.FC<NovoLaudoProps> = ({
  report,
  externalClient,
}: NovoLaudoProps) => {
  const [firstChangeTeam, setFirstChangeTeam] = useState(true);
  const [openModalEmail, setOpenModalEmail] = useState(false);
  //Report
  const [idTeam, setIdTeam] = useState<string | number>(report?.idTeam || 0);
  const [clinic, setClinic] = useState<{
    id?: string;
    name: string;
  }>({
    id: report?.idClinic ? report?.idClinic : report?.clinic ? "0" : "",
    name: report?.clinic || "",
  } as any);

  const [suspectDiagnosis, setSuspectDiagnosis] = useState<string>(
    report?.suspectDiagnosis || ""
  );
  const [examDate, setExamDate] = useState<string>(
    report ? formatDateToDateTime(report.examDate) : ""
  );
  const [vetRequester, setVetRequester] = useState<string>(
    report?.vetRequester || ""
  );
  const [vetResponsible, setVetResponsible] = useState<string>(
    report?.vetResponsible || ""
  );

  const [patient, setPatient] = useState<string>(report?.patient || "");
  const [age, setAge] = useState<string>(report?.age || "");
  const [sex, setSex] = useState<string>(report?.sex || "");
  const [breedType, setBreedType] = useState(report?.breedType || "Gato");
  const [breed, setBreed] = useState<string>(report?.breed || "");

  const [tutor, setTutor] = useState<string>(report?.tutor || "");
  const [password, setPassword] = useState<string>(report?.password || "");

  const [reportText, setReportText] = useState<string>(
    report?.reportText || ""
  );

  const [images, setImages] = useState<any[]>(
    (report?.images.map((imageFile) => {
      return {
        ...imageFile,
        base: imageFile.link,
      };
    }) as any) || []
  );

  const [categories, setCategories] = useState<any | null>(
    report?.categories.map((category) => ({
      id: category.id,
      value: category.id,
    })) || []
  );

  const [infos, setInfos] = useState<NewInfos>({} as NewInfos);
  const [categoriesClinic, setCategoriesClinic] = useState<any[] | undefined>(
    []
  );
  const [canEdit, setCanEdit] = useState<boolean>(
    Boolean(report?.userIsOwner || !report?.id)
  );
  const [uploadedImages, setUploadedImages] = useState<any[]>(
    report?.images || []
  );

  const [examPrice, setExamPrice] = useState<string>("R$ 0,00");

  useEffect(() => {
    setCategories(null);
  }, [clinic, idTeam]);

  useEffect(() => {
    if (idTeam === (report?.idTeam || 0) && firstChangeTeam) {
      return setFirstChangeTeam(false);
    }
    setClinic({
      id: "",
      name: "",
    });
  }, [idTeam]);

  useEffect(() => {
    setExamPrice(
      (categories
        ? categories?.reduce((acc, category) => acc + category.price, 0)
        : 0
      ).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    );
  }, [categories]);

  useEffect(() => {
    if (clinic.id) {
      setCategoriesClinic(
        infos.clinics
          ?.find((clinicF) => clinic.id === clinicF.id)
          ?.categories.map((category) => category.id)
      );
    }
  }, [clinic]);

  useEffect(() => {
    if (!report || !report.cannotSeePrivateContent) {
      setLoadingExternally(true);
      ReportsApi.getNewInfos()
        .then((res) => {
          setInfos(res);
          if (!res.canChooseTeam) {
            setIdTeam(res.team?.id!);
          }
          if (report?.categories.length) {
            setCategories(
              report.categories.map((category) => {
                const categoryFound = res.categories
                  .concat(
                    res.clinics.find(
                      (clinicRes) => clinicRes.id === category.idClinic
                    )?.categories || []
                  )
                  .find((cat) => cat.id === category.id);
                return {
                  value: `${category.id}`,
                  idClinic: category.idClinic,
                  label: categoryFound.name,
                  price: category.price,
                };
              })
            );
          }
        })
        .finally(() => setLoadingExternally(false));
    }
  }, []);

  const onRemoveImageFile = (fileName: string) => {
    setUploadedImages(uploadedImages.filter((file) => file.name !== fileName));
  };

  const history = useHistory();

  const downloadPdf = async () => {
    const response = await api.get(`/reports/pdf/${report!.id}`, {
      headers: {
        password: report!.password,
      },
    });
    const pdfName: any = (response.headers.get as any)("Pdf-Name");
    const link = document.createElement("a");
    link.href = `data:application/pdf;base64,${response.data}`;
    link.download = pdfName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const validateReport = (report: any) => {
    if (!report.patient) {
      toast.error("Paciente é obrigatório");
      return false;
    }
    if (!report.clinic) {
      toast.error("A clínica é obrigatória");
      return false;
    }
    if (!report.examDate) {
      toast.error("Data do exame é obrigatória");
      return false;
    }
    if (!report.reportText) {
      toast.error("O texto do laudo é obrigatório");
      return false;
    }
    if (!report.tutor) {
      toast.error("O tutor é obrigatório");
      return false;
    }
    if (!report.sex) {
      toast.error("O sexo é obrigatório");
      return false;
    }
    return true;
  };

  const saveReport = () => {
    const reportToSave = {
      idTeam: idTeam === 0 ? null : idTeam,
      idClinic: clinic.id === "0" ? null : clinic.id,
      clinic: clinic.name,
      categories: categories?.map((category) => ({
        id: category.value,
        price: category.price,
        idClinic: category.idClinic,
      })),
      suspectDiagnosis,
      examDate,
      vetRequester,
      patient,
      age,
      sex,
      breedType,
      breed,
      tutor,
      password,
      reportText,
      images,
      uploadedImages,
    } as any;
    if (!validateReport(reportToSave)) {
      return;
    }
    setLoadingExternally(true);
    if (report) {
      return ReportsApi.updateReport({
        id: report.id,
        ...reportToSave,
      } as any)
        .then((res) => {
          toast.success("Laudo atualizado com sucesso!");
          history.push(`/laudo/${res.id}`);
        })
        .catch(() => {})
        .finally(() => setLoadingExternally(false));
    }
    ReportsApi.saveReport(reportToSave)
      .then((res) => {
        toast.success("Laudo salvo com sucesso!");
        history.push(`/laudo/${res.id}`);
      })
      .catch((err) => {
        console.log("ERR teeste", err);
        toast.error(err.data.message);
      })
      .finally(() => setLoadingExternally(false));
  };

  const content = (
    <div>
      {report?.cannotSeePrivateContent ? null : (
        <>
          <div style={{ display: "flex", marginBottom: "1.5%" }}>
            <FormControl
              style={{
                width: "170px",
                marginRight: "1%",
              }}
            >
              <InputLabel id="team-label" required>
                Equipe
              </InputLabel>
              <Select
                labelId="team-label"
                id="team"
                value={idTeam}
                defaultValue="0"
                disabled={!canEdit || !infos.canChooseTeam}
                label="Equipe"
                onChange={(e) =>
                  setIdTeam(
                    e.target.value == "0"
                      ? parseInt(e.target.value)
                      : e.target.value
                  )
                }
              >
                {infos.canChooseTeam ? (
                  <MenuItem value={0}>Pessoal</MenuItem>
                ) : null}
                {infos.team ? (
                  <MenuItem
                    selected={!infos.canChooseTeam}
                    value={infos.team.id}
                  >
                    {infos.team.name}
                  </MenuItem>
                ) : null}
              </Select>
            </FormControl>

            <form style={{ top: "-24px", position: "relative" }}>
              <label
                style={{
                  fontSize: ".75rem",
                  lineHeight: 2,
                  position: "relative",
                  top: "12px",
                  left: "6px",
                  backgroundColor: "white",
                  zIndex: 5,
                  padding: "0px 4px",
                  color: "#585252",
                }}
                id="aria-label"
                htmlFor="clinic-input"
              >
                Clinica *
              </label>

              <CreatableSelect
                isClearable
                inputId="clinic-input"
                name="clinic"
                isDisabled={!canEdit}
                onCreateOption={(e) => {
                  setClinic({
                    name: e,
                  });
                }}
                placeholder="Selecione a clínica"
                value={{
                  value: clinic.id,
                  label: clinic.name,
                }}
                onChange={(e) => {
                  setClinic({
                    id: e?.value,
                    name: e?.label || "",
                  });
                }}
                styles={{
                  menu: (provided: any) => ({
                    ...provided,
                    backgroundColor: "#ffffff", // Define o fundo das opções como branco
                    zIndex: 999, // Garante que o menu fique em cima de outros elementos
                  }),
                  control: (styles) => ({
                    ...styles,
                    height: "56px",
                    maxWidth: "250px",
                    minWidth: "200px",
                  }),
                  option(base, props) {
                    return {
                      ...base,
                      backgroundColor: "#ffffff", // Fundo branco ou cinza claro ao passar o mouse
                      color: "#000", // Cor do texto
                      cursor: "pointer",
                    };
                  },
                }}
                options={infos.clinics
                  ?.filter((clinic) =>
                    idTeam ? clinic.idTeam === idTeam : !clinic.idTeam
                  )
                  .map((clinic) => ({
                    value: clinic.id,
                    label: clinic.name,
                  }))}
              />
            </form>
            <form style={{ top: "-24px", position: "relative" }}>
              <label
                style={{
                  fontSize: ".75rem",
                  lineHeight: 2,
                  position: "relative",
                  top: "12px",
                  left: "16px",
                  backgroundColor: "white",
                  zIndex: 5,
                  padding: "0px 4px",
                  color: "#585252",
                }}
                id="aria-label"
                htmlFor="categories-input"
              >
                Categoria
              </label>
              <Select2
                inputId="categories-input"
                isMulti
                value={categories}
                onChange={(e) => setCategories(e)}
                placeholder="Selecione a categoria"
                isClearable={false}
                name="categories"
                isDisabled={!canEdit}
                styles={{
                  menu: (provided: any) => ({
                    ...provided,
                    backgroundColor: "#ffffff", // Define o fundo das opções como branco
                    zIndex: 999, // Garante que o menu fique em cima de outros elementos
                  }),
                  control: (styles) => ({
                    ...styles,
                    marginLeft: "10px",
                    height: "56px",
                    maxWidth: "250px",
                    minWidth: "200px",
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    height: "56px",
                    overflowY: "auto",
                  }),
                  option(base, props) {
                    return {
                      ...base,
                      backgroundColor: "#ffffff", // Fundo branco ou cinza claro ao passar o mouse
                      color: "#000", // Cor do texto
                      cursor: "pointer",
                    };
                  },
                }}
                noOptionsMessage={() => "Nenhuma categoria encontrada"}
                options={infos.categories
                  ?.filter((category) => {
                    const teamPermission = idTeam
                      ? category.idTeam === idTeam
                      : true;
                    const clinicPermission = clinic.id
                      ? !categoriesClinic?.includes(category.id)
                      : true;
                    return teamPermission && clinicPermission;
                  })
                  .concat(
                    clinic.id
                      ? infos.clinics.find((clinicElement) => {
                          return clinicElement.id === clinic.id;
                        })?.categories || []
                      : ([] as any[])
                  )
                  ?.map((category) => {
                    return {
                      value: `${category.id}`,
                      idClinic: (category as any).idClinic,
                      label: category.name,
                      price: category.value,
                    };
                  })}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </form>
            <TextField
              id="outlined-basic"
              label="Preço"
              type="text"
              disabled={true}
              style={{
                marginRight: "1%",
                marginLeft: "10px",
                minWidth: "130px",
                maxWidth: "130px",
              }}
              value={examPrice}
            />
          </div>
        </>
      )}

      <div style={{ display: "flex", marginBottom: "1.5%" }}>
        <TextField
          id="outlined-basic"
          label="Suspeita Clínica"
          variant="outlined"
          disabled={!canEdit}
          style={{ flexGrow: 20, flexShrink: 1, marginRight: "1%" }}
          value={suspectDiagnosis}
          onChange={(e) => setSuspectDiagnosis(e.target.value)}
        />

        <TextField
          id="create-laudo-date"
          label="Data do exame"
          disabled={!canEdit}
          required
          type="datetime-local"
          style={{
            flexGrow: 1,
            flexShrink: 1,
            marginRight: "1%",
          }}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={new Date().toISOString().slice(0, 16)}
          value={examDate}
          onChange={(e) => setExamDate(e.target.value)}
        />

        <TextField
          id="outlined-basic"
          label="Vet. Requisitante"
          variant="outlined"
          disabled={!canEdit}
          style={{ marginRight: "1%", flexGrow: 1, flexShrink: 1 }}
          value={vetRequester}
          onChange={(e) => setVetRequester(e.target.value)}
        />

        <TextField
          id="outlined-basic"
          label="Vet. Responsável"
          variant="outlined"
          disabled={true}
          style={{ marginRight: "1%", flexGrow: 1, flexShrink: 1 }}
          value={vetResponsible}
        />
      </div>
      <div
        style={{
          color: "black !important",
          display: "flex",
          marginBottom: "1.5%",
        }}
      >
        <TextField
          id="outlined-basic"
          label="Paciente"
          required
          variant="outlined"
          disabled={!canEdit}
          style={{ flexGrow: 2, flexShrink: 1, marginRight: "1%" }}
          value={patient}
          onChange={(e) => setPatient(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          label="Idade"
          type="text"
          disabled={!canEdit}
          style={{
            flexGrow: 1,
            flexShrink: 1,
            marginRight: "1%",
          }}
          value={age}
          onChange={(e) => setAge(e.target.value)}
        />

        <FormControl
          style={{
            width: "100px",
            marginRight: "1%",
          }}
        >
          <InputLabel id="sexo-label">Sexo *</InputLabel>
          <Select
            labelId="sexo-label"
            id="sexo"
            value={sex}
            disabled={!canEdit}
            label="Sexo"
            onChange={(e) => setSex(e.target.value)}
          >
            <MenuItem value={"M"}>Macho</MenuItem>
            <MenuItem value={"F"}>Femea</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          style={{
            width: "114px",

            marginRight: "1%",
          }}
        >
          <InputLabel id="especie-label">Espécie</InputLabel>
          <Select
            labelId="especie-label"
            id="especie"
            disabled={!canEdit}
            value={breedType}
            label="Espécie"
            onChange={(e) => setBreedType(e.target.value as Raca)}
          >
            <MenuItem value={"Felino"}>Felino</MenuItem>
            <MenuItem value={"Canino"}>Canino</MenuItem>
            <MenuItem value={"Outro"}>Outro</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="outlined-basic"
          label="Raça"
          variant="outlined"
          type="text"
          disabled={!canEdit}
          style={{
            width: "110px",
            marginRight: "1%",
          }}
          InputProps={{
            inputProps: { min: 0 },
          }}
          value={breed}
          onChange={(e) => setBreed(e.target.value)}
        />
      </div>
      <div style={{ display: "flex", marginBottom: "1.5%" }}>
        <TextField
          id="outlined-basic"
          label="Tutor"
          required
          variant="outlined"
          disabled={!canEdit}
          style={{
            marginRight: "1%",
            flexGrow: 1,
            flexShrink: 1,
          }}
          value={tutor}
          onChange={(e) => setTutor(e.target.value)}
        />

        {report?.cannotSeePrivateContent ? null : (
          <>
            <TextField
              label="Senha"
              type="text"
              disabled={!canEdit}
              style={{
                flexGrow: 1,
                flexShrink: 1,
                marginRight: "1%",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </>
        )}
      </div>

      <div style={{ display: "flex", marginBottom: "1.5%" }}>
        <TextEditor
          canEdit={canEdit}
          onChange={setReportText}
          value={reportText}
        ></TextEditor>
      </div>
      <div className="dropzone-report">
        <span style={{ fontSize: "x-large" }}>Imagens</span>
        <DropZone
          canEdit={canEdit}
          files={images}
          setFiles={setImages}
          onRemove={uploadedImages ? onRemoveImageFile : () => {}}
          typeFile="image"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <Button
          text="Enviar email"
          callback={() => setOpenModalEmail(true)}
          hidden={!canEdit}
          style={{
            backgroundColor: "#F6F6F6",
            color: "#585252",
            fontSize: "14px",
          }}
        />
        <Button
          text="Baixar PDF"
          callback={() => downloadPdf()}
          hidden={report?.id === undefined}
          options={{
            backgroundColor: "orange",
            hoverBackgroundColor: "darkorange",
          }}
          style={{
            marginLeft: "1.5%",
            color: "#585252",
            fontSize: "14px",
          }}
        />
        <Button
          text="Salvar"
          callback={() => saveReport()}
          hidden={!canEdit}
          style={{
            marginLeft: "1.5%",
            fontSize: "14px",
          }}
        />
      </div>
    </div>
  );
  return externalClient ? (
    <ContentContainer className="external-client-container">
      {content}
    </ContentContainer>
  ) : (
    <DefaultTemplate
      title={
        report
          ? `Laudo ${report.patient} - ${formatDateStringToDateBR(
              report.examDate
            )}`
          : "Novo Laudo"
      }
    >
      {content}
      {report?.id && (
        <ModalEmail
          reportId={report.id}
          openModalEmail={openModalEmail}
          setOpenModalEmail={setOpenModalEmail}
          clinicEmail={
            infos?.clinics?.find((clinicInfo) => clinicInfo.id == clinic.id)
              ?.email
          }
        />
      )}
    </DefaultTemplate>
  );
};

export default Laudo;
