import { styled } from "@mui/material/styles";

export const InsideImageDiv = styled("div")({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff",
  fontSize: "16px",
  opacity: "0",
  transition: "opacity 0.3s ease-in-out",
  cursor: "pointer",
});

export const ExternalImageDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  gap: "20px",
});

export const StyledImage = styled("img", {
  shouldForwardProp(propName) {
    return propName !== "isSquare";
  },
})<any>(({ isSquare }) => ({
  width: "100%",
  height: "100%",
  borderRadius: isSquare ? "10%" : "50%",
  objectFit: "cover",
  transition: "opacity 0.3s ease-in-out",
}));
