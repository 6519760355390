import { styled, TextField, Typography } from "@mui/material";

export const TextFieldFilter = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
  },
});

export const DivInputsFilter = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
});

export const CleanFilterText = styled(Typography)({
  color: "#007bff",
  cursor: "pointer",
  "&:hover": {
    color: "#0056b3",
  },
});

export const HeaderPopover = styled("div")({
  backgroundColor: "#EEEDED",
  padding: "10px 20px",
  borderBottom: "1px solid #DAD7D7",
});

export const BottomPopover = styled("div")({
  marginTop: "20px",
  padding: "0px 20px 10px 20px",
  borderTop: "1px solid #DAD7D7",
  "& .MuiBox-root": {
    marginTop: "10px",
  },
});
