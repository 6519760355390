import React, { useState } from "react";
import { Grid, TextField, Box } from "@mui/material";
import Password from "../../components/password-field";
import { AuthApi } from "../../services/modules/auth";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/modules/user/reducer";
import { toast } from "react-toastify";
import AuthScreen from "../../components/auth-screen";
import { SubMessageLink } from "../../components/sub-message-link";
import { DefaultButton } from "../../components/default-button";
import { setLoadingExternally } from "../../providers/loading.provider";
const LoginPage = () => {
  const [email, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    const allowCookies = localStorage.getItem("cookieConsent");
    setLoadingExternally(true);
    await AuthApi.login({
      email,
      password,
      allowRefreshToken: allowCookies === "true",
    })
      .then((dataToken) => {
        dispatch(loginAction(dataToken));
        const path = "laudos";
        history.push(path);
      })
      .catch((err) => {
        toast.error("Usuário ou senha inválidos");
      })
      .finally(() => setLoadingExternally(false));
  };
  return (
    <AuthScreen>
      <>
        <TextField
          variant="outlined"
          margin="normal"
          style={{ marginTop: "50px" }}
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={email}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Password password={password} setPassword={setPassword} />
        <SubMessageLink href="/recuperar-senha">
          Esqueci minha senha
        </SubMessageLink>
        <DefaultButton onClick={handleLogin}>Login</DefaultButton>
      </>
    </AuthScreen>
  );
};

export default LoginPage;
