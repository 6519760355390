import { api } from "../../store/store";

interface Clinic {
  id?: string;
  team: boolean;
  name: string;
  email?: string;
  phone: string;
  address: {
    cep?: string;
    street: string;
    number?: string;
    complement?: string;
    neighborhood: string;
    city: string;
  };
  categories: {
    id?: string;
    name: string;
    value: string;
  }[];
}

export const getClinics = async (clinicName: string) => {
  const response = await api.get(
    "/clinics",
    clinicName
      ? {
          params: {
            name: clinicName,
          },
        }
      : {}
  );
  return response.data;
};

export const getClinicsCategories = async (idClinic: string) => {
  const response = await api.get(`/clinics/categories/${idClinic}`);
  return response.data;
};

export const createClinicApi = async (clinic: Clinic) => {
  const response = await api.post("/clinics", clinic);
  return response.data;
};

export const updateClinicApi = async (clinic: Clinic) => {
  const response = await api.put(`/clinics/${clinic.id}`, clinic);
  return response.data;
};

export const deleteClinicApi = async (idClinic: string) => {
  const response = await api.delete(`/clinics/${idClinic}`);
  return response.data;
};
