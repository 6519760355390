import { createSlice } from "@reduxjs/toolkit";

interface IUserState {
  user?: {
    id: string;
    name: string;
    email: string;
    type: string;
    image?: string;
    crmv?: string;
    telefone: string;
    especialidade?: string;
  };
  token?: {
    access_token: string;
    token_type: string;
    expiresIn: number;
    expiresAt: number;
  };
}

/* const getToken = () => {
  if (!localStorage.getItem("token")) {
    return undefined;
  }
  const token = JSON.parse(localStorage.getItem("token")!);
  if (token.expiresAt < Date.now()) {
    const [refreshToken, { isLoading: isLoadingRefreshToken }] =
      useRefreshTokenMutation();
    return refreshToken({ refreshToken: token.refresh_token })
      .unwrap()
      .catch(() => undefined);
  }
  return {token};
}; */
export const userSlice = createSlice({
  name: "user",
  initialState: () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (user && token) {
      if (JSON.parse(token).expiresAt > Date.now()) {
        return {
          user: JSON.parse(localStorage.getItem("user")!),
          token: JSON.parse(localStorage.getItem("token")!),
        } as IUserState;
      }
    }
    return {
      user: undefined,
      token: undefined,
    } as IUserState;
  },
  reducers: {
    loginAction: (state, action) => {
      const payload = action.payload;
      state.user = payload.user;
      const { token } = payload;
      state.token = {
        access_token: token.access_token,
        token_type: token.token_type,
        expiresIn: token.expiresIn,
        expiresAt: token.expiresAt,
      };
      localStorage.setItem("token", JSON.stringify(state.token));
      localStorage.setItem("user", JSON.stringify(payload.user));
    },
    logoutAction: (state) => {
      state.user = undefined;
      state.token = undefined;
      localStorage.setItem("token", "");
      localStorage.setItem("user", "");
    },
    setPicureAction: (state, action) => {
      if (state.user) {
        state.user.image = action.payload.url;
      }
    },
    updateLocalUserData: (state, action) => {
      if (action.payload) {
        state.user = action.payload;
        localStorage.setItem("user", JSON.stringify(action.payload));
      }
    },
  },
});
export const {
  loginAction,
  logoutAction,
  setPicureAction,
  updateLocalUserData,
} = userSlice.actions;
export const selectUser = (state: any) => state.user;
