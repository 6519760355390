import React, { createContext, useContext, useEffect, useState } from "react";
import { MiddleLoading } from "../components/loading_div";

interface LoadingContextType {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

let externalSetLoading: ((value: boolean) => void) | undefined;

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [loading, setLoading] = useState(false);

  // Armazena a referência para `setLoading` para uso externo

  externalSetLoading = (value: boolean) => {
    let loadingAux = loadings;
    if (value) {
      loadingAux.push(true);
    } else {
      loadingAux.pop();
    }
    setLoadings(loadingAux);
    if (loadingAux.length === 0) {
      setLoading(false);
    } else if (loadingAux.length === 1) {
      setLoading(true);
    }
  };
  return (
    <LoadingContext.Provider
      value={{
        loading: loading,
        setLoading: setLoading,
      }}
    >
      {children}
      <MiddleLoading isLoading={loading} />
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading deve ser usado dentro do LoadingProvider");
  }
  return context;
};

// Função para acessar `setLoading` fora de componentes React
export const setLoadingExternally = (value: boolean) => {
  if (externalSetLoading) {
    return externalSetLoading(value);
  }
};
