import { Link, styled } from "@mui/material";

export const SubMessageText = styled(Link)({
  color: "#9F9A9A",
  textDecoration: "none",
  ":hover": {
    color: "#676565",
    textDecoration: "underline",
    textDecorationColor: "#676565",
  },
});

export const SubMessageDiv = styled("div")({
  textAlign: "left",
  width: "100%",
});
