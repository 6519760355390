import { styled } from "@mui/material";

export const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "rgb(255 255 255 / 53%)",
  backgroundBlendMode: "soft-light",
});

export const LoginForm = styled("form")({
  backgroundColor: "#ffffff",
  padding: "40px",
  borderRadius: "8px",
  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
});

export const Label = styled("label")({
  display: "block",
  marginBottom: "8px",
  fontWeight: "bold",
});

export const Input = styled("input")({
  width: "100%",
  padding: "12px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginBottom: "24px",
  fontSize: "16px",
});

export const Button = styled("button")({
  width: "100%",
  padding: "14px",
  border: "none",
  borderRadius: "4px",
  backgroundColor: "#007bff",
  color: "#fff",
  cursor: "pointer",
  fontSize: "16px",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
});
