import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Routes from "./routes/routes";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { loginAction, logoutAction } from "./store/modules/user/reducer";
import { useHistory } from "react-router";
import { AuthApi } from "./services/modules/auth";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { ServiceProvider } from "./services/apiProvider";
import { CookieConsent } from "./components/cookie";
import { LoadingProvider } from "./providers/loading.provider";

export default function RootPage() {
  const dispatch = useDispatch();
  const userState = useSelector((state: any) => state.user);
  const [userIsLogged, setUserIsLogged] = useState(Boolean(userState.user));
  const history = useHistory();

  const getSessionUser = async () => {
    const tokenStorage = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (tokenStorage) {
      const tokenData = JSON.parse(tokenStorage);
      if (tokenData.expiresAt > Date.now() && user) {
        dispatch(
          loginAction({
            user: JSON.parse(user),
            token: tokenData,
          })
        );
        setUserIsLogged(true);
        if (window.location.pathname === "/login") history.push("/laudos");
      } else {
        AuthApi.refreshToken()
          .then((data) => {
            dispatch(loginAction(data));
            setUserIsLogged(true);
            if (window.location.pathname === "/login") history.push("/laudos");
          })
          .catch(() => {
            dispatch(logoutAction());
            history.push("/login");
          });
      }
    }
  };

  useEffect(() => {
    const isLogged = userState.user && userState.token;
    if (!isLogged) {
      getSessionUser();
    } else {
      setUserIsLogged(isLogged);
    }
  }, [userState]);

  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <ServiceProvider>
          <div>
            <CookieConsent />
            <Routes userIsLogged={userIsLogged} user={userState.user} />
            <ToastContainer limit={2} />
          </div>
        </ServiceProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}
