import React, { useEffect, useRef, useState } from "react";
import { FileUpload, UploadFile } from "@mui/icons-material";

import { DropZoneContainer, TextoDestacado } from "./style";
import { toast } from "react-toastify";
import { DropzoneImageList } from "./image-list";

interface DropZoneProps {
  files: File[];
  setFiles: (newFiles: File[]) => void;
  typeFile: string;
  onRemove?: (fileName: string) => void;
  canEdit?: boolean;
}

const DropZone: React.FC<DropZoneProps> = ({
  files,
  setFiles,
  typeFile,
  onRemove,
  canEdit = true,
}) => {
  const [dragging, setDragging] = useState<boolean>(false);
  const [width, setWidth] = useState(500);
  const [updateScreenTrigger, setUpdateScreenTrigger] =
    useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!dragging) setDragging(true);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);

    const files = Array.from(e.dataTransfer.files);
    await appendFiles(files);
  };

  const handleClick = () => {
    if (canEdit && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      await appendFiles(Array.from(files));
    }
  };

  const appendFiles = async (newFiles: File[]) => {
    if (!canEdit) return;
    let invalidFiles: string[] = [];
    let validFiles = newFiles.filter((file) => {
      const acceptTypes =
        typeFile === "doc"
          ? ["application/pdf", "application/doc", "application/txt"]
          : ["image"];
      const valid =
        acceptTypes.length === 1
          ? file.type.startsWith(acceptTypes[0])
          : acceptTypes.includes(file.type);
      if (!valid) {
        invalidFiles.push(file.name);
      }
      return valid;
    });

    if (invalidFiles.length) {
      toast.error(`Os arquivos ${invalidFiles.join(", ")} não são aceitos!`);
    }
    setFiles([...files, ...validFiles]);
  };

  const sliceFileName = (fileName: string) => {
    const maxLength = 10;
    if (fileName.length <= maxLength) return fileName;
    return `${fileName.slice(0, maxLength)}...`;
  };

  useEffect(() => {
    files.map((file: any) => {
      if (!file.base) {
        const reader = new FileReader();
        reader.onload = (e) => {
          file.base = e.target!.result as string;
          setUpdateScreenTrigger(!updateScreenTrigger);
        };
        reader.readAsDataURL(file);
      }
    });
  }, [files]);

  const elementRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        setWidth(width);
      }
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const getMessage = () => {
    return (typeFile === "doc" ? ["PDF", "DOC", "TXT"] : ["IMAGEM"]).join(", ");
  };

  return (
    <DropZoneContainer
      canEdit={canEdit}
      dragging={canEdit ? dragging : false}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      draggable={!canEdit}
    >
      <div
        ref={elementRef}
        style={{
          backgroundColor: "#E2E2E2",
          justifyContent: "center",
          height: "100%",
          flex: 3,
          maxHeight: "500px",
        }}
        onClick={handleClick}
      >
        {files && files.length > 0 && (
          <div
            ref={elementRef}
            style={{
              flex: 1,
              textAlign: "left",
              marginRight: "10px",
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <DropzoneImageList
              images={files as any}
              width={width}
              setImages={setFiles}
              onRemove={onRemove}
            />
          </div>
        )}
        {!files.length && canEdit && (
          <>
            <FileUpload />
            <p>
              Arraste e solte ou <TextoDestacado>selecione</TextoDestacado> um
              arquivo.
            </p>
            <p style={{ color: "#F6F6F6" }}>{getMessage()}</p>
          </>
        )}
        {!files.length && !canEdit && <p>Nenhum arquivo encontrado</p>}
        <input
          ref={fileInputRef}
          multiple
          type="file"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />
      </div>
    </DropZoneContainer>
  );
};

export default DropZone;
