import { SubMessageDiv, SubMessageText } from "./styles";

export interface SubMessageLinkProps {
  children: React.ReactNode;
  href: string;
}
export const SubMessageLink = (props: SubMessageLinkProps) => {
  return (
    <SubMessageDiv>
      <SubMessageText href={props.href}>{props.children}</SubMessageText>
    </SubMessageDiv>
  );
};
