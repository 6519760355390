import { api } from "../../store/store";

export interface ResetPasswordProps {
  email: string;
  password: string;
  token: string;
}
export const getUser = async () => {
  const response = await api.get("/users/");
  return response.data;
};

export const getUsersTeam = async (user: string) => {
  const response = await api.get(
    "/users/team",
    user
      ? {
          params: {
            user,
          },
        }
      : {}
  );
  return response.data;
};

export const saveUserTeam = async (user: {
  name: string;
  email: string;
  isAdmin: boolean;
}) => {
  const response = await api.post("/users/team", user);
  return response.data;
};

export const getPicture = async () => {
  const response = await api.get("/users/picture");
  return response.data;
};

export const uploadProfilePicture = async (id: string, profilePicture: any) => {
  const response = await api.post(`/users/update_profile_picture/${id}`, {
    profilePicture,
  });
  return response.data;
};

export const recoverPassword = async (email: string) => {
  const response = await api.post("/users/recover-password", { email });
  return response.data;
};

export const resetPassword = async (props: ResetPasswordProps) => {
  const response = await api.post("/users/reset-password", {
    email: props.email,
    password: props.password,
    token: props.token,
  });
  return response.data;
};

export const deleteUser = async (idUser: string) => {
  const response = await api.delete("/users/", { data: { idUser } });
  return response.data;
};

export const deletePicture = async (name: string) => {
  const response = await api.delete("/users/picture", {
    data: {
      name,
    },
  });
  return response.data;
};
