import React from "react";
import { Container } from "./style";

interface ContentContainerProps {
  children?: React.ReactNode;
  className?: string;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  className,
}) => {
  return <Container className={className}>{children}</Container>;
};

export default ContentContainer;
