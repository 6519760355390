import { MiddlewareAPI } from "@reduxjs/toolkit";
import axios from "axios";
import { loginAction, logoutAction } from "../../store/modules/user/reducer";
import { toast } from "react-toastify";

// Cria uma instância do Axios
export const configureApi = (
  dispatch: MiddlewareAPI["dispatch"],
  getState: MiddlewareAPI["getState"]
) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL, // Substitua pela URL do seu backend
    withCredentials: true, // Permite enviar cookies HTTP-only
  });

  api.interceptors.request.use((config) => {
    const state = getState();
    const token = state.user.token?.access_token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  const retriedRequests = new Set();

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error.response?.status === 401 &&
        originalRequest.url !== "/auth/refresh" &&
        !retriedRequests.has(originalRequest.url)
      ) {
        retriedRequests.add(originalRequest.url);

        try {
          const response = await api.post("/auth/refresh");
          const newAccessToken = response.data;

          // Atualiza o token no Redux
          dispatch(loginAction(newAccessToken));

          // Atualiza o header do originalRequest com o novo token
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

          // Reexecuta a requisição original com o novo token
          return api(originalRequest)
            .then((response) => {
              retriedRequests.delete(originalRequest.url);
              return response;
            })
            .catch((error) => {
              if (error.response?.status === 401) {
                retriedRequests.delete(originalRequest.url);
                dispatch(logoutAction());
                toast.error("Sessão expirada, faça login novamente");
              }
              return Promise.reject(error);
            });
        } catch (refreshError: any) {
          if (
            refreshError.response?.status === 401 &&
            refreshError.response?.data?.message === "Refresh token not found"
          ) {
            toast.error("Sessão expirada, faça login novamente!");
            toast.warning(
              "Para evitar que você seja deslogado(a) com frequencia, aceite nossos cookies para manter sessão!"
            );
          }
          dispatch(logoutAction());
          retriedRequests.delete(originalRequest.url);
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
  return api;
};
