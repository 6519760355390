import { styled } from "@mui/material";

export const CustomButton = styled("button", {
  shouldForwardProp: (prop) => prop !== "options",
})<any>((props) => ({
  color: "#FFFFFF",
  borderRadius: "10px",
  backgroundColor: props.options?.backgroundColor ?? "#207868",
  padding: "8px",
  border: "none",
  boxShadow: "0px 3px 3px 0px rgba(0, 0, 0, 0.125)",
  fontSize: "14px",
  fontWeight: "bold",

  "&:hover": {
    backgroundColor: props.options?.hoverBackgroundColor ?? "#144F44",
  },
}));
