import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../store/modules/user/reducer";

const PrivateRoute = ({
  component: Component,
  permissions = [],
  ...rest
}: any) => {
  const user = useSelector(selectUser).user;
  return (
    <Route
      {...rest}
      render={(props) =>
        user && (!permissions.length || permissions.includes(user.type)) ? (
          <Component user={user} {...props} isAdmin={user.isAdmin} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
