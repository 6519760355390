import { Typography } from "@mui/material";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Estilos do editor

interface TextEditorProps {
  value: string;
  onChange: (value: string) => void;
  canEdit: boolean;
}
const TextEditor = ({ value, onChange, canEdit }: TextEditorProps) => {
  const test = (v, delta, source, editor) => {
    return onChange(v);
  };
  return (
    <div style={{ width: "100%" }}>
      <Typography variant="h6">Laudo</Typography>
      <ReactQuill
        value={value}
        readOnly={!canEdit}
        onChange={(v, delta, source, editor) => test(v, delta, source, editor)}
        theme="snow"
        modules={{
          toolbar: canEdit
            ? [
                [{ font: [] }, { size: [] }], // Font size & family
                ["bold", "italic", "underline"], // Text formatting
                [{ color: [] }, { background: [] }], // Font color and background color
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // Subscript/Superscript
                ["link"], // Links and Images
              ]
            : [],
        }}
      />
    </div>
  );
};

export default TextEditor;
