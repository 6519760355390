import { AxiosInstance, AxiosResponse } from 'axios';
import { IHttpClient } from '../interfaces/base';


export class AxiosHttpClient implements IHttpClient {
    private axiosInstance: AxiosInstance;
  
    constructor(axiosInstance: AxiosInstance) {
      this.axiosInstance = axiosInstance;
    }
  
    private responseBody<T>(response: AxiosResponse<T>): T {
      return response.data;
    }
  
    async get<T>(url: string): Promise<T> {
      return this.axiosInstance.get<T>(url).then(this.responseBody);
    }
  
    async getOne<T>(url: string, id: string | number): Promise<T> {
      return this.axiosInstance.get<T>(`${url}/${id}`).then(this.responseBody);
    }
  
    async post<T>(url: string, body: {}): Promise<T> {
      return this.axiosInstance.post<T>(url, body).then(this.responseBody);
    }
  
    async put<T>(url: string, body: {}): Promise<T> {
      return this.axiosInstance.put<T>(url, body).then(this.responseBody);
    }
  
    async delete<T>(url: string): Promise<T> {
      return this.axiosInstance.delete<T>(url).then(this.responseBody);
    }
  }
  