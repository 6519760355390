import { api } from "../../store/store";

export interface DataLogin {
  email: string;
  password: string;
  allowRefreshToken: boolean;
}

export interface RefreshToken {
  refreshToken: string;
}

class Auth {
  async login(dataLogin: DataLogin) {
    return api.post("/auth/login", dataLogin).then((response) => response.data);
  }

  async refreshToken() {
    return api.post("/auth/refresh").then((response) => response.data);
  }
}

export const AuthApi = new Auth();
