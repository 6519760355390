import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useState } from "react";
import { DefaultButton } from "../../../components/default-button";
import CreatableSelect from "react-select/creatable";
import { ClosedCaption } from "@mui/icons-material";
import { ReportsApi } from "../../../services/modules/reports";
import { setLoadingExternally } from "../../../providers/loading.provider";
import { toast } from "react-toastify";

interface ModalEmailProps {
  reportId: string;
  openModalEmail: boolean;
  setOpenModalEmail: (value: boolean) => void;
  clinicEmail?: string;
}

export const ModalEmail: React.FC<ModalEmailProps> = ({
  reportId,
  openModalEmail,
  setOpenModalEmail,
  clinicEmail,
}: ModalEmailProps) => {
  const [email, setEmail] = React.useState<{ label: string; value: string }>({
    label: "",
    value: "",
  });

  const sendEmail = () => {
    setLoadingExternally(true);
    ReportsApi.sendEmail({ idReport: reportId, email: email.value })
      .then((response) => {
        setOpenModalEmail(false);
        toast.success("Email enviado com sucesso");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => setLoadingExternally(false));
  };
  return (
    <Modal open={openModalEmail} onClose={() => setOpenModalEmail(false)}>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6"> Envio de laudo por email</Typography>
        </Box>
        <CreatableSelect
          isClearable
          inputId="Email"
          name="email-send"
          formatCreateLabel={(e) => `Enviar para ${e}`}
          onCreateOption={(e: string) => {
            setEmail({
              label: e,
              value: e,
            });
          }}
          placeholder="Selecione/digite o email"
          value={email}
          onChange={(e) => {
            setEmail(e as any);
          }}
          styles={{
            control: (base) => ({
              ...base,
              marginBottom: "16px",
            }),
          }}
          options={
            [clinicEmail]
              .filter((e) => e)
              .map((email) => ({ value: email, label: email })) as any
          }
        />
        <Box mt={2}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <DefaultButton
              style={{ backgroundColor: "#8f1515", width: "40%" }}
              onClick={() => setOpenModalEmail(false)}
            >
              Cancelar
            </DefaultButton>
            <Button
              variant="contained"
              onClick={sendEmail}
              endIcon={<SendIcon />}
            >
              Enviar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
